/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account {
        pk
        sk
        accountId
        providerId
        createdAt
        updatedAt
        accountName
        parentId
        email
        churnScore
        creditLimit
        subscribedPlans {
          planId
          planName
        }
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount($input: DeleteAccountInput!) {
    deleteAccount(input: $input) {
      account {
        pk
        sk
        accountId
        providerId
        createdAt
        updatedAt
        accountName
        parentId
        email
        churnScore
        creditLimit
        subscribedPlans {
          planId
          planName
        }
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        pk
        sk
        accountId
        providerId
        createdAt
        updatedAt
        accountName
        parentId
        email
        churnScore
        creditLimit
        subscribedPlans {
          planId
          planName
        }
      }
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      device {
        pk
        sk
        deviceId
        accountId
        providerId
        createdAt
        updatedAt
        deviceName
        parentId
        email
      }
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice($input: DeleteDeviceInput!) {
    deleteDevice(input: $input) {
      device {
        pk
        sk
        deviceId
        accountId
        providerId
        createdAt
        updatedAt
        deviceName
        parentId
        email
      }
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      device {
        pk
        sk
        deviceId
        accountId
        providerId
        createdAt
        updatedAt
        deviceName
        parentId
        email
      }
    }
  }
`;

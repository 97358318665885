import { axiosInstance as axios } from '../../utils/axiosInstances'
import { getSettingsQuery } from './queries'
import getSymbolFromCurrency from 'currency-symbol-map'
import telcos from './utils/telcos.json'

const telcoCountries = []
const telcosByCountries = {}

for (const telco of telcos) {
  if (telcoCountries.indexOf(telco.country) < 0) {
    telcoCountries.push(telco.country)
  }
  if (!telcosByCountries[telco.country]) {
    telcosByCountries[telco.country] = {
      telcos: [],
      telcosWithCode: []
    }
  }
  telcosByCountries[telco.country].telcos.push(telco.telco)
  telcosByCountries[telco.country].telcosWithCode.push({
    telco: telco.telco,
    mcc: telco.mcc,
    mnc: telco.mnc,
    label: `${telco.telco} - ${telco.mcc}/${telco.mnc}`,
    value: `${telco.telco} - ${telco.mcc}/${telco.mnc}`
  })
}

export const initialState = () => {
  return {
    carrierSelectRoutingInformation: 'ARIN',
    numberPortabilityRoutingInformation: 'ARIN',
    isOnNetMode: true,
    telcos,
    telcoCountries,
    telcosByCountries,
    telcoCountry: telcoCountries[0],
    telco: null
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    },
    setSettings (state, newValue) {
      state.settings = newValue
      state.currencyCode = state.settings.json_data.general.currency
      state.currencySymbol = getSymbolFromCurrency(state.currencyCode)
      state.settingsLoaded = true
    },
    setIsOnNetMode (state, isOnNetMode) {
      state.isOnNetMode = isOnNetMode
    },
    setCarrierSelectRoutingInformation (state, carrierSelectRoutingInformation) {
      state.carrierSelectRoutingInformation = carrierSelectRoutingInformation
      state.isOnNetMode = (state.carrierSelectRoutingInformation === state.numberPortabilityRoutingInformation)
    },
    setNumberPortabilityRoutingInformation (state, numberPortabilityRoutingInformation) {
      state.numberPortabilityRoutingInformation = numberPortabilityRoutingInformation
      state.isOnNetMode = (state.carrierSelectRoutingInformation === state.numberPortabilityRoutingInformation)
    },
    setTelcoCountry (state, telcoCountry) {
      state.telcoCountry = telcoCountry
    },
    setTelco (state, telco) {
      state.telco = telco
    }
  },
  actions: {
    async loadSettings (context) {
      try {
        const settings = (await axios.post(
          context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
          getSettingsQuery(
            context.rootState.providerId
          ),
          context.rootGetters.graphQLHeaders
        )).data.data.getSettings
        settings.json_data = JSON.parse(settings.json)
        context.commit('setSettings', settings)
      } catch (err) {
        console.error('Error loading settings', err)
      }
    },
    updateIsOnNetMode (context, { isOnNetMode }) {
      context.commit('setIsOnNetMode', isOnNetMode)
      if (!context.state.carrierSelectRoutingInformation) {
        context.commit('setCarrierSelectRoutingInformation', 'ARIN')
      }
      if (isOnNetMode && context.state.carrierSelectRoutingInformation !== context.state.numberPortabilityRoutingInformation) {
        context.commit('setNumberPortabilityRoutingInformation', context.state.carrierSelectRoutingInformation)
      } else if (context.state.carrierSelectRoutingInformation === context.state.numberPortabilityRoutingInformation) {
        context.commit('setNumberPortabilityRoutingInformation', Math.random().toString(36).slice(2).slice(0, 4).toUpperCase())
      }
    },
    updateTelco (context, { telco }) {
      context.commit('setTelco', telco)
      const telcoCountry = context.state.telcoCountry
      const telcoWithCode = context.state.telcosByCountries[telcoCountry].telcosWithCode.filter(telcoWithCode => telcoWithCode.value === telco)[0]
      context.commit('updateMcc', telcoWithCode.mcc, { root: true })
      context.commit('updateMnc', telcoWithCode.mnc, { root: true })
    }
  }
}

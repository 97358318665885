<template>
    <img @click="iconClicked()" src="@/assets/flex-data.png" alt="Flex icon" id="flexdata">
</template>

<script>
import getStorageSizeInBytesByUnit from '@/utils/storageSizes'

export default {
  props: ['ratingGroup', 'name', 'megabytes', 'deviceId'],
  emits: ['inputsRequired'],
  methods: {
    iconClicked () {
      console.log(`Flex: RG ${this.ratingGroup}, Name ${this.name}, MBs ${this.megabytes}, Device ${this.deviceId}`)
      var validated = true
      if (!this.ratingGroup) {
        this.$emit('ratingGroupRequired')
        validated = false
      }
      if (!this.name) {
        this.$emit('nameRequired')
        validated = false
      }
      if (!this.megabytes) {
        this.$emit('megabytesRequired')
        validated = false
      }
      if (!this.deviceId) {
        this.$emit('deviceIdRequired')
        validated = false
      }
      if (!validated) {
        return
      }

      const message = `${this.name} ACTIVITY: Charging RG ${this.ratingGroup} Data for ${this.megabytes} MB`
      console.log(message)
      const volume = this.megabytes * getStorageSizeInBytesByUnit('MB') // convert MB to octets
      const ratingGroupNumber = this.ratingGroup
      const payload = {
        deviceId: this.$props.deviceId,
        volume: volume,
        ratingGroupNumber: ratingGroupNumber,
        message: message
      }
      this.$store.dispatch('chargeAccount', payload)
    }
  }
}
</script>

<style scoped>
#flexdata {
    max-width: 80px;
    border-radius: 10px;
}
</style>

<template>
<span class="custom-badge-container">
  <span class="badge custom-badge text-body tot-badge">
    <slot></slot>
    <span class="tot-badge-copy-btn-holder" v-if="copyValue">
      &nbsp;
      <CTooltip :content="copyBtnTooltip" placement="top">
        <template #toggler="{ on }">
          <CButton v-on="on" color="link" class="p-0 tot-copy-btn border-0" @click="copyToClipboard">
            <font-awesome-icon class="tot-copy-icon" icon="fa-regular fa-copy" />
          </CButton>
        </template>
      </CTooltip>
    </span>
  </span>
</span>
</template>

<script>
export default {
  props: {
    copyValue: String
  },
  data () {
    return {
      copyBtnTooltipText: 'Copy'
    }
  },
  computed: {
    copyBtnTooltip: {
      get () {
        return this.copyBtnTooltipText
      },
      set (newValue) {
        this.copyBtnTooltipText = newValue
      }
    }
  },
  methods: {
    async copyToClipboard () {
      if (this.copyValue) {
        await navigator.clipboard.writeText(this.copyValue)
        this.copyBtnTooltip = 'Copied!'
        setTimeout(() => {
          this.copyBtnTooltip = 'Copy'
        }, 2000)
      }
    }
  }
}
</script>

<style>
.tot-badge .tot-badge-copy-btn-holder {
  display: none;
}
.tot-badge:hover .tot-badge-copy-btn-holder {
  display: inline;
}
.tot-badge .tot-copy-btn {
  font-size: 10px;
  line-height: 10px;
  border: none !important;
}
.tot-badge .tot-copy-icon {
  font-size: 10px;
}
.custom-badge-container {
  display: inline-block;
  min-width: 95px;
}
</style>

<template>
  <CContainer fluidGrow>
    <CRow>
      <CCol lg="1">
      </CCol>
      <CCol lg="10">
        <div class="signin-form-container d-flex flex-column justify-content-center text-start">
          <div class="d-grid gap-4">
            <img src="@/assets/totogi-logo-rgb.svg" alt="logo" style="max-width: 100px" />
            <div class="pb-2"></div>
            <CAlert color="danger" v-if="errorMessage">
              <font-awesome-icon icon="fa-solid fa-circle-exclamation" /> {{errorMessage}}
            </CAlert>
            <div class="fs-3 pt-2 signin-form-header">
              <span>Welcome to</span>
              <br />
              <span class="fw-bolder">Totogi Charging Emulator</span>
            </div>
            <CForm class="d-grid gap-4" novalidate :validated="isFormSubmitted" @submit.prevent="signIn($event)">
              <div>
                <CFormInput
                  v-model="email"
                  type="email"
                  id="email"
                  placeholder="Email"
                  class="p-3"
                  feedbackInvalid="Please enter email"
                  required
                />
              </div>
              <div>
                <CFormInput
                  v-model="password"
                  type="password"
                  class="p-3"
                  id="inputPassword"
                  placeholder="Password"
                  feedbackInvalid="Please enter password"
                  required
                />
              </div>
              <CButton color="primary" :disabled="signingIn" class="py-3 fw-bolder" type="submit">
                <CSpinner v-if="signingIn" color="white" size="sm"/>
                <span v-else>LOG IN</span>
              </CButton>
            </CForm>
          </div>
        </div>
        <div style="position: absolute; bottom: 10px; right: 10px;" class="text-start">
          <CFormSelect
            label="Region"
            v-model="awsRegionName"
            :options="[{label: 'us-east-1', value: 'us-east-1'}, {label: 'ap-southeast-1', value: 'ap-southeast-1'}]"
            @change="awsRegionChanged($event.target.value)">
          </CFormSelect>
        </div>
      </CCol>
      <CCol lg="1">
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'SignIn',
  data () {
    return {
      email: '',
      password: '',
      errorMessage: false,
      formSubmitted: false,
      windowMessageEvent: null,
      awsRegionName: 'us-east-1'
    }
  },
  computed: {
    isFormSubmitted: {
      get () {
        return this.formSubmitted
      },
      set (newValue) {
        this.formSubmitted = newValue
      }
    },
    signingIn () {
      return this.$store.state.signIn.signingIn
    }
  },
  beforeRouteUpdate (to, from) {
    this.awsRegionName = this.$store.state.awsRegionInfo.aws_appsync_region
  },
  methods: {
    awsRegionChanged (newAWSRegionName) {
      this.$store.commit('setAWSRegionInfo', newAWSRegionName)
      this.$cookies.set('awsRegionName', newAWSRegionName)
      console.log('Updating AWS Region', newAWSRegionName, this.$store.state.awsRegionInfo, this.$cookies.get('awsRegionName'))
    },
    signIn: function (event) {
      this.isFormSubmitted = true
      if (event?.target?.elements && [...event.target.elements].map(element => element.checkValidity()).indexOf(false) > -1) {
        return
      }
      const data = {
        AuthParameters: {
          USERNAME: this.email,
          PASSWORD: this.password
        },
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: this.$store.state.awsRegionInfo.aws_user_pools_web_client_id
      }
      this.$store.dispatch('signIn/signIn', data).then(response => {
        if (response.status && response.status === 200) {
          const idToken = this.$store.state.idToken
          const providerId = this.$store.state.providerId
          if (idToken && providerId) {
            this.$cookies.set('idToken', idToken)
            this.$cookies.set('providerId', providerId)
            this.$cookies.set('awsRegionName', this.awsRegionName)
          }
        } else if (response.status && response.status !== 200) {
          if (response.data?.__type && (response.data?.__type === 'UserNotFoundException' || response.data?.__type === 'NotAuthorizedException')) {
            this.errorMessage = 'Invalid email or password'
            this.isFormSubmitted = false
          }
        }
      })
    },
    addWindowMessageEvent () {
      console.log('Adding window message event for Kiosk sign in')
      this.clearWindowMessageEvent()
      this.windowMessageEvent = window.addEventListener('message', (event) => {
        const { type, email, password } = event.data
        if (type === 'kiosk.login') {
          console.log('Received Kiosk Login, signing in')
          this.email = email
          this.password = password
          this.signIn()
        }
      })
      console.log('Added event listener for Kiosk sign in', this.windowMessageEvent)
    },
    clearWindowMessageEvent () {
      if (this.windowMessageEvent) {
        window.removeEventListener(this.windowMessageEvent)
      }
    },
    async checkUserSession () {
      this.$store.commit('setCurrentUser', null)
      await this.$store.dispatch('user/getCurrentUser', true)
      if (this.$store.state.user) {
        this.$router.push({ name: 'Home' })
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.addWindowMessageEvent()
    })
  },
  beforeRouteLeave () {
    this.clearWindowMessageEvent()
  },
  beforeUnmount () {
    this.clearWindowMessageEvent()
  },
  mounted () {
    this.checkUserSession()
    this.addWindowMessageEvent()
    this.awsRegionName = this.$store.state.awsRegionInfo.aws_appsync_region
  }
}
</script>

<style>
.signin-form-container {
  height: 100vh;
}

.signin-form-header {
  color: #001d3d;
}
</style>

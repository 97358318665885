<template>
<div class="front-page-container w-100">
  <div class="p-0 front-page-charge-ahead-container">
    <img src="@/assets/charge-ahead.svg" class="d-block front-page-charge-ahead-image"  />
  </div>
  <div class="front-page-circle1-container">
    <img src="@/assets/circle-1.svg" class="d-block animation-two front-page-circle1-image" />
  </div>
  <div class="front-page-rectange1-container">
    <img src="@/assets/rectangle-1.svg" class="d-block animation-three front-page-rectangle1-image" />
  </div>
  <div class="front-page-circle2-container">
    <img src="@/assets/circle-2.svg" class="d-block animation-two front-page-circle2-image" />
  </div>
  <div class="front-page-rectange2-container">
    <img src="@/assets/rectangle-2.svg" class="d-block animation-four front-page-rectangle2-image" />
  </div>
  <div class="front-page-polygon1-container">
    <img src="@/assets/polygon-1.svg" class="d-block animation-one front-page-polygon1-image" />
  </div>
  <div class="front-page-form-container d-flex">
    <slot></slot>
  </div>
</div>
</template>

<script>

export default { }
</script>

<style>
.front-page-container {
  background-color: rgb(0,29,61);
  display: grid;
  grid-template-columns: repeat(3,minmax(0,1fr));
  grid-template-rows: repeat(4,minmax(0,1fr));
  height: 100vh;
  width: 100vw;
}

.front-page-charge-ahead-container {
  justify-content: center;
  align-items: center;
  display: flex;
  grid-row-start: 1 !important;
  grid-row-end: span 1 !important;
  grid-column-start: 1 !important;
  grid-column-end: span 3 !important;
}

.front-page-charge-ahead-image {
  width: 66.666667%;
  max-width: 100%;
}

.front-page-circle1-container {
  grid-row-start: 1 !important;
  grid-column-start: 1 !important;
}

.front-page-circle1-image {
  width: 4rem !important;
  margin-left: 3rem !important;
  margin-top: 0.5rem !important;
  max-width: 100%;
}

.front-page-rectange1-container {
  grid-row-start: 1 !important;
  grid-column-start: 3 !important;
}

.front-page-rectangle1-image {
  width: 8rem !important;
  margin-left: 3.5rem !important;
  max-width: auto;
}

.front-page-circle2-container {
  grid-column-start: 3 !important;
  grid-row-start: 1 !important;
}

.front-page-circle2-image {
  width: 7rem !important;
  margin-left: 5rem !important;
  margin-top: 2.5rem !important;
  animation-delay: 1.5s;
}

.front-page-rectange2-container {
  grid-row-start: 1 !important;
  grid-column-start: 1 !important;
}

.front-page-rectangle2-image {
  width: 5rem !important;
  margin-top: 7rem !important;
}

.front-page-polygon1-container {
  grid-column-start: 1!important;
  grid-row-start: 1!important;
}

.front-page-polygon1-image {
  width: 4rem!important;
  margin-left: 3rem!important;
  margin-top: 8rem!important;
}

.front-page-form-container {
  background-color: rgba(255,255,255, 1);
  border-top-left-radius: 0.75rem!important;
  border-top-right-radius: 0.75rem!important;
  overflow: auto!important;
  grid-row-start: 2 !important;
  grid-row-end: span 3 !important;
  grid-column-start: span 3 !important;
  grid-column-end: span 3 !important;
  z-index: 2;
}

@media (min-width: 768px) {
  .front-page-container {
    grid-template-columns: repeat(5,minmax(0,1fr)) !important;
    grid-template-rows: repeat(5,minmax(0,1fr)) !important;
  }

  .front-page-charge-ahead-container {
    grid-row-start: 3 !important;
    grid-row-end: span 1 !important;
    grid-column-start: span 3 !important;
    grid-column-end: span 3 !important;
  }

  .front-page-charge-ahead-image {
    width: 58.333333%;
    margin-left: -3rem;
    margin-top: -6rem;
  }

  .front-page-circle1-container {
    grid-column-start: 2 !important;
    grid-column-end: span 1 !important;
  }

  .front-page-circle1-image {
    width: 12rem !important;
    margin-left: -1rem !important;
    margin-top: -2rem !important;
  }

  .front-page-rectangle1-image {
    width: 100% !important;
    margin-left: 1rem!important;
    margin-top: -1rem !important;
  }

  .front-page-circle2-container {
    grid-row-start: 2 !important;
  }

  .front-page-circle2-image {
    width: 100% !important;
    margin-left: 10rem !important;
    margin-top: -1rem !important;
  }

  .front-page-rectange2-container {
    grid-row-start: 4 !important;
  }

  .front-page-rectangle2-image {
    margin-left: -4rem!important;
    width: 12rem !important;
    margin-top: -4rem !important;
  }

  .front-page-rectangle1-image {
    margin-left: -1.5rem!important;
  }

  .front-page-polygon1-container {
    grid-column-start: 2!important;
    grid-row-start: 4!important;
  }

  .front-page-polygon1-image {
    width: 12rem !important;
    margin-left: -13rem!important;
    margin-top: 6rem!important;
  }

  .front-page-form-container {
    border-top-left-radius: 0.75rem!important;
    border-bottom-left-radius: 0.75rem!important;
    border-top-right-radius: 0!important;
    grid-row-start: span 5 !important;
    grid-row-end: span 5 !important;
    grid-column-start: span 2 !important;
    grid-column-end: span 2 !important;
  }
}

@media (min-width: 1024px) {
  .front-page-rectangle2-image {
    margin-left: -1.5rem!important;
  }
}

@keyframes animationOne {
  0% {
    transform: none;
  }
  to {
    transform: translateY(10%);
  }
}

@keyframes animationTwo {
  0% {
    transform: none;
  }
  to {
    transform: translateY(-10%);
  }
}

@keyframes animationThree {
  0% {
    transform: none;
  }
  to {
    transform: translateX(15%);
  }
}

@keyframes animationFour {
  0% {
    transform: none;
  }
  to {
    transform: translateX(-15%);
  }
}

.animation-one {
  animation: animationOne 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite alternate;
}

.animation-two {
  animation: animationTwo 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite alternate;
}

.animation-three {
  animation: animationThree 3.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite alternate;
}

.animation-four {
  animation: animationFour 3.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite alternate;
}
</style>

import { axiosInstance as axios } from '../../utils/axiosInstances'
import {
  topUpUrl,
  topUpData
} from './utils/charging'

import { proxyUrl } from '@/store/common'

export const initialState = () => {
  return {}
}

export default {
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    }
  },
  actions: {
    async topUp (context, providerId, deviceId, volume, token) {
      const url = await topUpUrl(context.rootState.awsRegionInfo.charging_url)
      const requestData = await topUpData(providerId, deviceId, volume)
      const requestBody = {
        url: url,
        requestData: requestData,
        token: token
      }
      const result = await axios.post(proxyUrl, requestBody)
      return result
    }
  }
}

<template>
  <router-view/>
</template>

<script>
import { DEFAULT_AWS_REGION } from './utils/constants'

export default {
  mounted () {
    const idToken = this.$cookies.get('idToken')
    const providerId = this.$cookies.get('providerId')
    const awsRegionName = this.$cookies.get('awsRegionName') || DEFAULT_AWS_REGION
    console.log('AWS Region in app', awsRegionName)
    if (idToken && providerId) {
      this.$store.commit('setIdToken', idToken, { root: true })
      this.$store.commit('setProviderId', providerId, { root: true })
    }
    this.$store.commit('setAWSRegionInfo', awsRegionName)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;400&display=swap');

body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif; /* poppins */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f6f6f6;
}

.custom-badge {
  background-color: #EDE5F4 !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

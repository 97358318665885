/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccounts = /* GraphQL */ `
  query GetAccounts($providerId: ID!, $accountIds: [String]) {
    getAccounts(providerId: $providerId, accountIds: $accountIds) {
      count
      scannedCount
      accounts {
        pk
        sk
        accountId
        providerId
        createdAt
        updatedAt
        accountName
        parentId
        email
        churnScore
        creditLimit
        subscribedPlans {
          planId
          planName
        }
      }
      nextToken
    }
  }
`;
export const getAccountsDevices = /* GraphQL */ `
  query GetAccountsDevices(
    $providerId: ID!
    $accountName: String
    $limit: Int
    $nextToken: String
  ) {
    getAccountsDevices(
      providerId: $providerId
      accountName: $accountName
      limit: $limit
      nextToken: $nextToken
    ) {
      count
      scannedCount
      accounts_devices {
        pk
        sk
        accountId
        providerId
        createdAt
        updatedAt
        accountName
        deviceId
        parentId
        email
        churnScore
        creditLimit
      }
      nextToken
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices($providerId: ID!) {
    getDevices(providerId: $providerId) {
      devices {
        pk
        sk
        deviceId
        accountId
        providerId
        createdAt
        updatedAt
        deviceName
        parentId
        email
      }
      nextToken
    }
  }
`;

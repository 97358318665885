<template>
<CModal
  :backdrop="true"
  :centered="true"
  alignment="center"
  :visible="showModal"
  @close="handleModalClose"
>
  <CModalHeader>
    <CModalTitle>
      Create Account
    </CModalTitle>
  </CModalHeader>
  <CModalBody class="text-start">
    <CRow>
      <CCol class="py-2">
        <CFormInput
          :disabled="processingCreateAccountAction"
          type="text"
          placeholder="Account ID"
          v-model="accountId"
          label="Account ID"
        />
        <CFormText class="text-start">Leave blank to auto-generate a UUID</CFormText>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="py-2">
        <CFormInput
          :disabled="processingCreateAccountAction"
          type="text"
          placeholder="Account Name"
          v-model="accountName"
          label="Account Name"
        />
        <CFormText class="text-start">Name is required</CFormText>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="py-2">
        <CFormCheck type="radio" :disabled="processingCreateAccountAction" id="prepaidAccountRadioBtn" inline label="Prepaid" value="prepaid" :checked="accountType === 'prepaid'" @click="accountType = 'prepaid'"/>
        <CFormCheck type="radio" :disabled="processingCreateAccountAction" id="postpaidRadioButton" inline label="Postpaid" value="postpaid" :checked="accountType === 'postpaid'" @click="accountType = 'postpaid'"/>
        <CFormText class="text-start" v-if="accountType === 'postpaid'">Prepaid account will have credit limit as zero</CFormText>
      </CCol>
    </CRow>
    <CRow v-if="accountType === 'postpaid'">
      <CCol>
        <hr />
      </CCol>
    </CRow>
    <CRow v-if="accountType === 'postpaid'">
      <CCol class="py-2">
        <CFormInput
          :disabled="processingCreateAccountAction"
          type="text"
          placeholder="Credit Limit"
          v-model="accountCreditLimit"
          label="Credit Limit"
        />
        <CFormText class="text-start" v-if="accountType === 'postpaid'">Credit Limit is required (negative number or zero will make this a prepaid account)</CFormText>
      </CCol>
    </CRow>
    <CRow v-if="accountType === 'postpaid'">
      <CCol class="py-2">
        <CFormSelect
          :disabled="processingCreateAccountAction"
          label="Timezone"
          v-model="postpaidAccountTimezone"
          :options="timezones">
        </CFormSelect>
      </CCol>
    </CRow>
    <CRow v-if="accountType === 'postpaid'">
      <CCol class="py-2">
        <CFormSelect
          :disabled="processingCreateAccountAction"
          label="Billing Day Of Month"
          v-model="postpaidAccountBillingDayOfMonth"
          :options="billingDaysOfMonth">
        </CFormSelect>
      </CCol>
    </CRow>
    <CRow v-if="accountType === 'postpaid'">
      <CCol class="py-2">
        <CFormSwitch size="xl" :disabled="processingCreateAccountAction" label="Long First Billing Cycle" id="longFirstBillingCycleChecked" :checked="postpaidAccountLongFirstBillingCycle"/>
      </CCol>
    </CRow>
  </CModalBody>
  <CModalFooter>
    <TotButton @button-click="createAccount" :disabled="processingCreateAccountAction">
      <template #initial>
        Create Account
      </template>
      <template #loading>
        Creating Account
      </template>
    </TotButton>
  </CModalFooter>
</CModal>
</template>

<script>
import TotButton from './common/tot-button.vue'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    processingCreateAccountAction: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedPlanVersionId: null,
      accountId: '',
      accountName: '',
      accountCreditLimit: '0.000001',
      accountType: 'prepaid',
      postpaidAccountBillingDayOfMonth: 1,
      postpaidAccountLongFirstBillingCycle: false,
      postpaidAccountTimezone: '+02:00',
      billingDaysOfMonth: Array.from(Array(31), (i, k) => { return { label: k + 1, value: k + 1 } }),
      timezones: Array.from(Array(27), (i, k) => {
        const offset = k - 12
        const hours = Math.floor(Math.abs(offset))
        const minutes = (Math.abs(offset) % 1) * 60
        const sign = offset < 0 ? '-' : '+'
        const label = `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        return {
          label: label,
          value: label
        }
      }),
      overrides: []
    }
  },
  components: { TotButton },
  emits: ['createAccount', 'overridesChanged', 'modalClosed'],
  methods: {
    handleModalClose () {
      this.$emit('modalClosed')
    },
    createAccount () {
      this.$emit('createAccount', {
        accountId: this.accountId,
        accountName: this.accountName,
        accountCreditLimit: this.accountCreditLimit,
        accountType: this.accountType,
        postpaidAccountTimezone: this.postpaidAccountTimezone,
        postpaidAccountBillingDayOfMonth: this.postpaidAccountBillingDayOfMonth,
        postpaidAccountLongFirstBillingCycle: this.postpaidAccountLongFirstBillingCycle
      })
    }
  }
}
</script>

<template>
<CModal
  :backdrop="true"
  :centered="true"
  alignment="center"
  :visible="showModal"
  @close="handleModalClose"
>
  <CModalHeader>
    <CModalTitle>
      <slot name="title">Enter a value</slot>
    </CModalTitle>
  </CModalHeader>
  <CModalBody>
    <CAlert class="p-2" color="warning" v-if="error">
      <font-awesome-icon icon="fa-solid fa-circle-exclamation" /> {{error}}
    </CAlert>
    <CFormInput
      :disabled="processingAction"
      ref="inputField"
      type="text"
      :placeholder="placeholder"
      v-model="inputValue"
    />
    <CFormText class="text-start" v-if="helpText">{{ helpText }}</CFormText>
  </CModalBody>
  <CModalFooter>
    <TotButton :loading="processingAction" @button-click="submitModal">
      <template #loading>
        <slot name=modalProcessingText>Processing</slot>
        &nbsp;
      </template>
      <template #initial>
        <slot name="modalSubmitText">Submit</slot>
      </template>
    </TotButton>
  </CModalFooter>
</CModal>
</template>

<script>
import TotButton from './tot-button.vue'
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: 'Enter value here'
    },
    processingAction: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      inputValue: ''
    }
  },
  emits: ['modalSubmitted', 'modalClosed'],
  methods: {
    handleModalClose () {
      this.$emit('modalClosed')
    },
    submitModal () {
      this.$emit('modalSubmitted', this.inputValue)
    }
  },
  watch: {
    showModal (newValue) {
      if (newValue) {
        // #TODO: Remove inoutField ref on the CFormInput and remove this line
        // this.$refs.inputField.$el.parentElement.querySelector('input').focus()
        this.inputValue = ''
      }
    }
  },
  components: { TotButton }
}
</script>

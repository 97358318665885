import { axiosInstance as axios } from '../../utils/axiosInstances'

import { notificationsUrl } from '@/store/common'

export const initialState = () => {
  return {}
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    }
  },
  actions: {
    async update (context, { accountId, deviceId }) {
      const idToken = await context.rootState.idToken
      const providerId = context.rootState.providerId
      let url = `${notificationsUrl}notifications?providerId=${providerId}&accountId=${accountId}`
      let result = await axios.get(
        url, {
          headers: {
            Authorization: `${idToken}|${context.rootState.awsRegionInfo.aws_appsync_region}`
          }
        }
      )
      let notifications = result.data.items
      for (let i = 0; i < notifications.length; i++) {
        context.commit('addLog', {
          deviceId: deviceId,
          log: {
            id: notifications[i].timestamp,
            text: notifications[i].message,
            style: 'notification'
          }
        }, { root: true })
      }
      url = `${notificationsUrl}notifications?accountId=${accountId}&deviceId=${deviceId}`
      result = await axios.get(
        url, {
          headers: {
            Authorization: `${idToken}|${context.rootState.awsRegionInfo.aws_appsync_region}`
          }
        }
      )
      notifications = result.data.items
      for (let i = 0; i < notifications.length; i++) {
        context.commit('addLog', {
          deviceId: deviceId,
          log: {
            id: notifications[i].timestamp,
            text: notifications[i].message,
            style: 'notification'
          }
        }, { root: true })
      }
      url = `${notificationsUrl}notifications?accountId=undefined&deviceId=${deviceId}`
      result = await axios.get(
        url, {
          headers: {
            Authorization: `${idToken}|${context.rootState.awsRegionInfo.aws_appsync_region}`
          }
        }
      )
      notifications = result.data.items
      for (let i = 0; i < notifications.length; i++) {
        context.commit('addLog', {
          deviceId: deviceId,
          log: {
            id: notifications[i].timestamp,
            text: notifications[i].message,
            style: 'notification'
          }
        }, { root: true })
      }
    }
  }
}

import { axiosInstance as axios } from '../../utils/axiosInstances'
import moment from 'moment'
import { getEventDataRecordsByDeviceQuery } from './queries'

export const initialState = () => {
  return {}
}

export default {
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    }
  },
  actions: {
    async getAndSummarizeEdrs (context, { deviceId }) {
      console.log('Fetching EDRS')
      const edges = await this.dispatch('getAllEdrs', { after: undefined, deviceId: deviceId })
      const summarizedEdges = []
      const multipleUnitInformations = []
      const allCharges = []
      edges.forEach(edge => {
        console.log('Edge', edge)
        try {
          const customData = JSON.parse(edge.node.customData)
          const createdAt = moment(edge.node.createdAt)
          if (!(customData.charginginformation?.multipleunitinformation || []).length) {
            return
          }
          /*
            Multiple unit information example
            {
              charges: [charge],
              grantedunit: { totalvolume: 52428800 },
              plansused: [],
              ratinggroup: 300,
              resultcode: "SUCCESS"
            }
          */
          for (const multipleUnitInformation of (customData.charginginformation?.multipleunitinformation || [])) {
            multipleUnitInformations.push(multipleUnitInformation)
            if (multipleUnitInformation && multipleUnitInformation.charges && multipleUnitInformation.charges.length > 0) {
              multipleUnitInformation.charges.forEach(charge => { allCharges.push(charge) })
            }
            const ratingGroup = multipleUnitInformation.ratinggroup
            if (!ratingGroup) {
              continue
            }
            if (multipleUnitInformation.resultcode === 'RATING_FAILED') {
              continue
            }
            /*
              Charge Example
              {
                appliedrate: 1,
                appliedtaxrate: 0,
                balanceused: "data.1GB.769c723c-d4e1-4701-a83b-b2dee8ca6582",
                chargedtax: 0,
                chargedtotal: 52428800,
                perunitrounding: 1024,
                roundingtype: "HALF_UP"
              }
            */
            if (!(multipleUnitInformation && multipleUnitInformation.charges && multipleUnitInformation.charges.length > 0)) {
              continue
            }
            for (const charge of multipleUnitInformation.charges) {
              summarizedEdges.push({
                createdAt,
                ratingGroup,
                usedUnits: charge.chargedtotal,
                planServiceId: charge.balanceused,
                planVersionsUsed: multipleUnitInformation.plansused,
                resultcode: multipleUnitInformation.resultcode
              })
            }
          }
        } catch (err) {
          console.error('Error processing EDR', err)
          console.info('Edge that caused the error', edge)
          console.info('Edge.node.customData that caused error', JSON.parse(edge.node.customData))
          throw err
        }
      })
      console.log('All EDR Sumary')
      console.log('Multiple Unit Information')
      console.table(multipleUnitInformations)
      console.log('All EDR Charges')
      console.table(allCharges)
      return summarizedEdges.filter(edge => edge !== null)
    },
    async getAllEdrs (context, { after, deviceId }) {
      const idToken = context.rootState.idToken
      const providerId = context.rootState.providerId
      const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
      const yesterday = moment.utc().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
      const eventDataRecordsResponse = await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        getEventDataRecordsByDeviceQuery(
          providerId,
          deviceId,
          yesterday,
          now,
          after
        ), {
          headers: {
            Authorization: `${idToken}`
          }
        }
      )
      const edges = eventDataRecordsResponse.data.data.getEventDataRecordsByDevice.edges
      const pageInfo = eventDataRecordsResponse.data.data.getEventDataRecordsByDevice.pageInfo
      if (pageInfo.hasNextPage !== false) {
        const nextEdges = await this.dispatch('getAllEdrs', { after: pageInfo.endCursor, deviceId: deviceId })
        return edges.concat(nextEdges)
      }
      return edges
    },
    async resetEdrs (context) {}
  }
}

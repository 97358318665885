import { axiosInstance as axios } from '../../utils/axiosInstances'
import getStorageSizeInBytesByUnit from '@/utils/storageSizes'
import { getAccountQuery } from './queries'
import {
  getMonetaryBalance,
  getAllServiceBalances
} from './utils/unifiedBalances'

export const initialState = () => {
  return {}
}

export default {
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    }
  },
  actions: {
    async getAccount (context, { accountId }) {
      console.log(`Fetching account ${accountId}`)
      const providerId = context.rootState.providerId
      const response = await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        getAccountQuery(accountId, providerId),
        context.rootGetters.graphQLHeaders
      )
      const result = {}
      const balanceData = JSON.parse(response.data.data.getAccount.balance.customData)
      // Testing wave 3
      try {
        const monetaryBalance = await getMonetaryBalance(balanceData.balance)
        context.commit('setCurrentMonetaryBalance', monetaryBalance.toString())
        console.log('Service Balance Original')
        console.table(balanceData.balance)
        const serviceBalances = await getAllServiceBalances(balanceData.balance)
        context.commit('setCurrentNonMonetaryBalances', serviceBalances)
        result.serviceBalances = serviceBalances
        result.monetaryBalance = monetaryBalance
        result.activePlanVersions = response.data.data.getAccount.activePlanVersions
        return result
      } catch (e) {
        console.log('Failed to do something in wave3 balances')
        console.log(e)
      }
      // Setting voice account balance
      try {
        // Try as if limited balance
        const currentVoiceBalanceSeconds = balanceData.balance.Voice.total
        const currentVoiceBalanceMinutes = Math.floor(currentVoiceBalanceSeconds / 60)
        context.commit('setCurrentVoiceBalanceMinutes', currentVoiceBalanceMinutes.toString())
        result.voiceBalanceMinutes = currentVoiceBalanceMinutes.toString()
      } catch (e) {
      // Try as if unlimited balance
      // TODO: Figure out if this differs at all from the limited balance
        try {
          const currentVoiceBalanceSeconds = balanceData.balance.voice.total
          const currentVoiceBalanceMinutes = Math.floor(currentVoiceBalanceSeconds / 60)
          context.commit('setCurrentVoiceBalanceMinutes', currentVoiceBalanceMinutes.toString())
          result.voiceBalanceMinutes = currentVoiceBalanceMinutes.toString()
        } catch (e) {
        // Try as if the service isn't on the plan
          try {
            const voiceAllocationNotFoundOnAccount = balanceData.balance.voice === undefined
            if (voiceAllocationNotFoundOnAccount && context.rootState.currentVoiceBalanceMinutes === 'No service') {
              context.commit('setCurrentVoiceBalanceMinutes', '0')
              result.voiceBalanceMinutes = '0'
            }
          } catch (e) {
            console.log('Real Error setting Voice balance')
            console.log(e)
            context.commit('setAccountDeviceError', true)
          }
        }
      }
      // Setting SMS account balance
      try {
        // Try as if limited balance
        const currentSmsBalance = balanceData.balance.Text.total
        context.commit('setCurrentSmsBalance', currentSmsBalance.toString())
        result.smsBalance = currentSmsBalance.toString()
      } catch (e) {
        // Try as if unlimited balance
        // TODO: Figure out if this differs at all from the limited balance
        try {
          const currentSmsBalance = balanceData.balance.sms.total
          context.commit('setCurrentSmsBalance', currentSmsBalance.toString())
          result.smsBalance = currentSmsBalance.toString()
        } catch (e) {
        // Try as if the service isn't on the plan
          try {
            const smsAllocationNotFoundOnAccount = balanceData.balance.sms === undefined
            if (smsAllocationNotFoundOnAccount && context.rootState.currentSmsBalance === 'No service') {
              context.commit('setCurrentSmsBalance', '0')
              result.smsBalance = '0'
            }
          } catch (e) {
            console.log('Real Error setting SMS balance')
            console.log(e)
            context.commit('setAccountDeviceError', true)
          }
        }
      }
      // Setting Data account balance
      try {
        // Try as if limited balance
        const currentDataBalanceBytes = balanceData.balance.Data.total
        const currentDataBalanceMb = Math.floor(currentDataBalanceBytes / getStorageSizeInBytesByUnit('MB'))
        context.commit('setCurrentDataBalanceMb', currentDataBalanceMb.toString())
        result.dataBalanceMb = currentDataBalanceMb.toString()
      } catch (e) {
        // Try as if unlimited balance
        // TODO: Figure out if this differs at all from the limited balance
        try {
          const currentDataBalanceBytes = balanceData.balance.data.total
          const currentDataBalanceMb = Math.floor(currentDataBalanceBytes / getStorageSizeInBytesByUnit('MB'))
          context.commit('setCurrentDataBalanceMb', currentDataBalanceMb.toString())
          result.dataBalanceMb = currentDataBalanceMb.toString()
        } catch (e) {
          // Try as if the service isn't on the plan
          try {
            const dataAllocationNotFoundOnAccount = balanceData.balance.data === undefined
            if (dataAllocationNotFoundOnAccount && context.rootState.currentDataBalanceMb === 'No service') {
              context.commit('setCurrentDataBalanceMb', '0')
              result.dataBalanceMb = '0'
            }
          } catch (e) {
            console.log('Real Error setting Data balance')
            console.log(e)
            context.commit('setAccountDeviceError', true)
          }
        }
      }
      // Setting Monetary account balance
      try {
        const currentMonetaryBalance = balanceData.balance.Monetary.total
        context.commit('setCurrentMonetaryBalance', currentMonetaryBalance.toString())
        result.monetaryBalance = currentMonetaryBalance.toString()
      } catch (e) {
        console.log('Real Error setting Data balance')
        console.log(e)
        context.commit('setAccountDeviceError', true)
      }
      return result
    }
  }
}

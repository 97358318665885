import { axiosInstance as axios, axiosGraphQlRequest } from '../../utils/axiosInstances'
import {
  createAccountQuery,
  deleteAccountQuery,
  CancelPlanSubscriptionByPlanVersionMutation,
  CancelPlanSubscriptionByPlanSubscriptionMutation,
  getAccountActivePlansQuery
} from '../modules/queries'
import { createAccount, updateAccount } from './utils/persistence/mutations'
import { getAccounts, getAccountsDevices } from './utils/persistence/queries'
import { subscribeToPlan, updateBalanceMutation } from './utils/totogi/mutations'
import { buildGraphQlPayload } from '@/utils/graphql_helpers'

import { buildReadableAllocationsOrBalances } from './utils/unifiedBalances'
import _ from 'lodash'
import moment from 'moment'
import { persistenceUrl } from '@/store/common'
import { BALANCE_TYPE_MONETARY } from '@/utils/constants'

export const initialState = () => {
  return {
    accounts: [],
    accountsDevices: [],
    accountsDevicesCount: 0,
    accountDevicesScannedCount: 0,
    accountsDevicesNextToken: null
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    },
    setAccounts (state, newValue) {
      state.accounts = newValue
    },
    setAccountsDevices (state, newValue) {
      state.accountsDevices = newValue
    },
    setAccountsDevicesResult (state, newValue) {
      state.accountsDevices = newValue.accounts_devices
      state.accountsDevicesCount = newValue.count
      state.accountDevicesScannedCount = newValue.scannedCount
      state.accountsDevicesNextToken = newValue.nextToken
      console.log('Counts for accounts devices', newValue.count, newValue.scannedCount)
    }
  },
  actions: {
    async delete (context, { accountId }) {
      await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        deleteAccountQuery(
          accountId,
          context.rootState.providerId
        ),
        {
          headers: {
            Authorization: `${context.rootState.idToken}`
          }
        }
      )
    },
    async create (context, { accountId, accountName = '', creditLimit = 0.0000001, timezone = '+00:00', billingDayOfMonth = 1, longFirstBillingCycle = false }) {
      const result = await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        createAccountQuery(
          accountId,
          context.rootState.providerId,
          creditLimit,
          timezone,
          billingDayOfMonth,
          longFirstBillingCycle), {
          headers: {
            Authorization: `${context.rootState.idToken}`
          }
        }
      )
      if (!(['CreateAccountPayload', 'AccountAlreadyExists'].indexOf(result?.data?.data?.createAccount?.__typename) > -1)) {
        return
      }
      await axios.post(
        persistenceUrl,
        buildGraphQlPayload(createAccount, {
          input: {
            accountId,
            accountName: accountName,
            providerId: context.rootState.providerId
          }
        }),
        context.rootGetters.persistenceGraphQLHeaders
      )
      await context.dispatch('updateEmulatorPersistence', {
        accountId,
        subscribedPlans: [],
        creditLimit: 0,
        churnScore: 0
      })
    },
    async credit (context, { accountId, amount }) {
      /* await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        creditAccountMutation(
          accountId,
          context.rootState.providerId,
          amount
        ), {
          headers: {
            Authorization: `${context.rootState.idToken}`
          }
        }
      )
      const account = await context.dispatch('getAccountAndActivePlans', { accountId })
      await context.dispatch('updateEmulatorPersistence', {
        accountId,
        creditLimit: account.creditLimit
      }) */
      const account = await context.dispatch('getAccountAndActivePlans', { accountId })
      const accountPlansAndInfo = await context.dispatch('retrieveAccountPlansAndInfo', { accountId: accountId, includeAllocations: false })
      const balances = accountPlansAndInfo.balances
      console.log('Balances')
      console.table(balances)
      for (const balance of balances) {
        const balanceId = balance.balanceId
        const balanceTypeId = balance.balanceType.balanceTypeId
        const balanceValue = isNaN(balance.balanceValue) ? 0 : balance.balanceValue
        console.log('Processing balance', balanceId, balanceTypeId, balanceValue, balanceTypeId === BALANCE_TYPE_MONETARY)
        if (balanceTypeId === BALANCE_TYPE_MONETARY) {
          await axiosGraphQlRequest(
            context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
            updateBalanceMutation,
            {
              input: {
                balanceId,
                providerId: context.rootState.providerId,
                accountId,
                balanceInfo: {
                  balanceTypeId,
                  balanceValue: Number(amount),
                  adjustment: 'CREDIT'
                }
              }
            },
            context.rootGetters.graphQLHeaders
          )
        }
      }
      return account.creditLimit
    },
    async getAccountAndActivePlans (context, { accountId }) {
      return (await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        getAccountActivePlansQuery(accountId, context.rootState.providerId),
        context.rootGetters.graphQLHeaders
      )).data.data.getAccount
    },
    async updateEmulatorPersistence (context, { accountId, subscribedPlans = null, creditLimit = null, churnScore = null }) {
      const input = {
        providerId: context.rootState.providerId,
        accountId: accountId
      }
      if (subscribedPlans) {
        input.subscribedPlans = subscribedPlans
      }
      if (creditLimit !== null && creditLimit !== undefined) {
        input.creditLimit = creditLimit
      }
      if (churnScore !== null && churnScore !== undefined) {
        input.churnScore = churnScore
      }
      return await axiosGraphQlRequest(
        persistenceUrl,
        updateAccount,
        {
          input: input
        },
        context.rootGetters.persistenceGraphQLHeaders
      )
    },
    async loadAccountsDevices (context, options) {
      const payload = { providerId: context.rootState.providerId, limit: 10 }
      if (options && options.accountName && options.accountName.length) {
        payload.accountName = options.accountName
      }
      if (options && options.nextToken) {
        payload.nextToken = options.nextToken
      }
      const result = (await axiosGraphQlRequest(
        persistenceUrl,
        getAccountsDevices,
        payload,
        context.rootGetters.persistenceGraphQLHeaders
      )).data.data.getAccountsDevices
      const accountIds = []
      result.accounts_devices.forEach(accountDevice => {
        accountDevice.accountIdText = accountDevice.accountId.split('-')[0]
        if (accountDevice.accountIdText.length > 8) {
          accountDevice.accountIdText = accountDevice.accountIdText.substr(0, 16)
        }
        if (accountDevice.deviceId) {
          accountDevice.deviceIdText = accountDevice.deviceId.split('-')[0]
          if (accountDevice.deviceIdText.length > 8) {
            accountDevice.deviceIdText = accountDevice.deviceIdText.substr(0, 16)
          }
        }
        accountDevice.createdAtText = moment.unix(accountDevice.createdAt).format('DD-MMM-YYYY')
        if (accountIds.indexOf(accountDevice.accountId) < 0) {
          accountIds.push(accountDevice.accountId)
        }
      })
      const accountsResult = (await axiosGraphQlRequest(
        persistenceUrl,
        getAccounts,
        { providerId: context.rootState.providerId, accountIds: accountIds },
        context.rootGetters.persistenceGraphQLHeaders
      )).data.data.getAccounts
      const accountsMap = {}
      accountsResult.accounts.forEach(account => {
        accountsMap[account.accountId] = account
      })
      result.accounts_devices.forEach(accountDevice => {
        const account = accountsMap[accountDevice.accountId]
        if (!account) {
          return
        }
        accountDevice.subscribedPlans = account.subscribedPlans || []
        accountDevice.subscribedPlansText = accountDevice.subscribedPlans.map(plan => plan.planName).join(', ')
        accountDevice.churnScore = account.churnScore || 0
        accountDevice.churnScoreText = `${Math.round((accountDevice.churnScore || 0) * 100)}%`
        accountDevice.creditLimit = account.creditLimit
        accountDevice.accountName = account.accountName
      })
      context.commit('setAccountsDevicesResult', result)
      const accountsToUpdate = {}
      if (options && options.syncAccounts) {
        const retrievedAccountsInfo = {}
        for (const index in context.state.accountsDevices) {
          const accountDevice = context.state.accountsDevices[index]
          retrievedAccountsInfo[accountDevice.accountId] = (retrievedAccountsInfo[accountDevice.accountId] ? retrievedAccountsInfo[accountDevice.accountId] : (await context.dispatch('retrieveAccountPlansAndInfo', { accountId: accountDevice.accountId, includeAllocations: false })))
          const accountPlansAndInfo = retrievedAccountsInfo[accountDevice.accountId]
          let persistenceUpdateRequired = false
          if (!accountsToUpdate[accountDevice.accountId]) {
            persistenceUpdateRequired = ((accountDevice.subscribedPlans && accountDevice.subscribedPlans.map(plan => `${plan.planId}${plan.planName}`).sort().join(',') !== accountPlansAndInfo.accountPlans.map(plan => `${plan.planId}${plan.planName}`).sort().join(',')) ||
              (accountDevice.creditLimit !== accountPlansAndInfo.creditLimit) ||
              (accountDevice.churnScore !== accountPlansAndInfo.churnScore))
          }
          accountDevice.subscribedPlans = accountPlansAndInfo.accountPlans
          accountDevice.subscribedPlansText = accountDevice.subscribedPlans.map(plan => plan.planName).join(', ')
          accountDevice.churnScore = accountPlansAndInfo.churnScore
          accountDevice.churnScoreText = `${Math.round((accountDevice.churnScore || 0) * 100)}%`
          accountDevice.creditLimit = accountPlansAndInfo.creditLimit
          if (persistenceUpdateRequired) {
            accountsToUpdate[accountDevice.accountId] = {
              accountId: accountDevice.accountId,
              providerId: context.rootState.providerId,
              subscribedPlans: accountDevice.subscribedPlans.map(plan => {
                return { planId: plan.planId, planName: plan.planName }
              }),
              creditLimit: accountDevice.creditLimit,
              churnScore: accountDevice.churnScore
            }
          }
        }
        console.log('Accounts to update', accountsToUpdate)
        Object.keys(accountsToUpdate).forEach(accountId => {
          const accountUpdatePayload = accountsToUpdate[accountId]
          context.dispatch('updateEmulatorPersistence', accountUpdatePayload)
        })
        context.commit('setAccountsDevices', [...context.state.accountsDevices])
      }
    },
    async subscribeToCurrentPlanVersion (context, { accountId, account = null, planId = null, creditAccount = false, overrides = [] }) {
      await axiosGraphQlRequest(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        subscribeToPlan,
        {
          input: {
            accountId,
            providerId: context.rootState.providerId,
            planId,
            overrides
          }
        },
        context.rootGetters.graphQLHeaders
      )
      const accountPlansAndInfo = await context.dispatch('retrieveAccountPlansAndInfo', { accountId: accountId, includeAllocations: false })
      const accountUpdatePayload = {
        accountId: accountId,
        providerId: context.rootState.providerId,
        subscribedPlans: accountPlansAndInfo.accountPlans.map(plan => {
          return { planId: plan.planId, planName: plan.planName }
        })
      }
      if (account) {
        account.subscribedPlans = accountPlansAndInfo.accountPlans
      }
      if (creditAccount) {
        const creditLimit = await context.dispatch('credit', {
          accountId,
          amount: 1000
        })
        accountUpdatePayload.creditLimit = creditLimit
        const balances = accountPlansAndInfo.balances
        for (const balance of balances) {
          const balanceId = balance.balanceId
          const balanceTypeId = balance.balanceType.balaneTypeId
          const balanceValue = isNaN(balance.balanceValue) ? 0 : balance.balanceValue
          if (balanceTypeId === BALANCE_TYPE_MONETARY) {
            await axiosGraphQlRequest(
              context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
              updateBalanceMutation,
              {
                input: {
                  balanceId,
                  providerId: context.rootState.providerId,
                  accountId,
                  balanceInfo: {
                    balanceTypeId,
                    balanceValue: (balanceValue + 1000)
                  }
                }
              },
              context.rootGetters.graphQLHeaders
            )
          }
        }
      }
      context.dispatch('updateEmulatorPersistence', accountUpdatePayload)
      return accountPlansAndInfo.accountPlans
    },
    async latestPlanSubscription (context, { activePlans }) {
      return _.sortBy(activePlans, function (plan) {
        return moment(plan.createdAt)
      }).reverse()[0]
    },
    async invokeCancelPlanSubscription (context, { accountId, planSubscriptionId = null }) {
      const payload = [accountId, context.rootState.providerId]
      const cancellationMutation = planSubscriptionId ? CancelPlanSubscriptionByPlanSubscriptionMutation : CancelPlanSubscriptionByPlanVersionMutation
      if (planSubscriptionId) {
        payload.push(planSubscriptionId)
      }
      return (await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        cancellationMutation(...payload),
        context.rootGetters.graphQLHeaders
      ))
    },
    async cancelPlanSubscription (context, { accountId, planVersionId = null, planSubscriptionIds = [], activePlans = [] }) {
      if (activePlans && activePlans.length && planVersionId) {
        if (!planSubscriptionIds) {
          planSubscriptionIds = []
        }
        activePlans.forEach(activePlan => {
          if (activePlan.planVersionId === planVersionId) {
            planSubscriptionIds.push(activePlan.planSubscriptionId)
          }
        })
      }
      console.log(accountId, planVersionId, planSubscriptionIds, activePlans)
      console.log('Cancelling plan subscriptions', planSubscriptionIds)
      if (planSubscriptionIds && planSubscriptionIds.length) {
        for (const planSubscriptionId of planSubscriptionIds) {
          await context.dispatch('invokeCancelPlanSubscription', { accountId, planSubscriptionId })
        }
      } else {
        await context.dispatch('invokeCancelPlanSubscription', { accountId, planSubscriptionId: null })
      }
    },
    async retrieveAccountPlansAndInfo (context, { accountId = null, account = null, includeAllocations = false }) {
      if (!account) {
        account = await context.dispatch('getAccountAndActivePlans', { accountId })
      }
      if (!context.rootState.allAvailablePlanInformation) {
        context.dispatch('planVersionAllocations/getPlanVersionInformation')
      }
      const accountPlans = (account.activePlanVersions || []).map(planSubscription => {
        console.log('Plan active subscription', planSubscription)
        const planVersion = planSubscription.planVersion
        const plan = context.rootState.allAvailablePlanInformation.filter(plan => plan.planVersionId === planVersion.id)[0]
        const subscriptionEndingAt = (planSubscription.to ? moment(planSubscription.to) : null)
        const planExpired = !subscriptionEndingAt || (moment() > plan.subscriptionEndingAt)
        const daysLeftInPlan = subscriptionEndingAt ? subscriptionEndingAt.diff(moment(), 'days') : null
        console.log('Validity', subscriptionEndingAt, planExpired, daysLeftInPlan)
        return {
          subscriptionStartedAt: moment(planSubscription.from),
          subscriptionEndingAt,
          createdAt: planVersion.createdAt,
          modifiedAt: planVersion.modifiedAt,
          overrides: planSubscription.overrides,
          planVersionName: planVersion.version,
          planName: plan?.planName || 'Plan not in available plans',
          planId: plan?.planId || null,
          planSubscriptionId: planSubscription.planSubscriptionId,
          planVersionId: planVersion.id,
          planVersionAllocation: context.dispatch('planVersionAllocations/getPlanVersionInformation', { planVersionId: planVersion.id }, { root: true }),
          planExpired,
          daysLeftInPlan
        }
      })
      if (!includeAllocations) {
        return { accountPlans, churnScore: account.churnScore, creditLimit: account.creditLimit, balances: account.balances }
      }
      const allocations = []
      for (const plan of accountPlans) {
        plan.planVersionAllocation = await plan.planVersionAllocation
        console.log('Overrides', plan.overrides)
        if (plan.planVersionAllocation.serviceAllowances) {
          console.log(plan.planVersionAllocation.serviceAllowances)
          if (plan.overrides && plan.planVersionAllocation.serviceNameMap) {
            const serviceNameMap = plan.planVersionAllocation.serviceNameMap
            const overrides = plan.overrides
            const serviceAllowances = plan.planVersionAllocation.serviceAllowances
            for (const serviceAllowance of serviceAllowances) {
              for (const key of Object.keys(serviceAllowance)) {
                if (key && serviceNameMap[key]) {
                  const overrideName = serviceNameMap[key]
                  const override = overrides.filter(overrideObject => overrideObject.name === overrideName)[0]
                  if (override) {
                    let overrideValue = override.value
                    if (isNaN(overrideValue)) {
                      try {
                        overrideValue = Number(JSON.parse(overrideValue)['recurring units'])
                        serviceAllowance[key] = overrideValue
                      } catch (err) {
                        console.error('Error parsing override value', overrideName, overrideValue)
                      }
                    } else {
                      serviceAllowance[key] = Number(overrideValue)
                    }
                  }
                }
              }
            }
          }
          plan.planVersionAllocation.serviceAllowances = await buildReadableAllocationsOrBalances(plan.planVersionAllocation.serviceAllowances, 'allocation')
          plan.planVersionAllocation.serviceAllowances.forEach(allocation => allocations.push(Object.assign({
            planVersionId: plan.planVersionId,
            subscriptionStartedAt: plan.subscriptionStartedAt
          }, allocation)))
        }
      }
      console.log('Built allocations', allocations)
      return { accountPlans, allocations, churnScore: account.churnScore, creditLimit: account.creditLimit, balances: account.balances }
    }
  }
}


import { axiosInstance as axios, plainAxiosInstance } from '../../utils/axiosInstances'
import { getCurrentUserQuery } from '@/store/modules/queries'

export const initialState = () => {
  return {}
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    }
  },
  actions: {
    async getCurrentUser (context, testingCredentials) {
      const idToken = context.rootState.idToken
      const axiosToUse = testingCredentials ? plainAxiosInstance : axios
      const response = await axiosToUse.post(context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint, getCurrentUserQuery(), {
        headers: {
          Authorization: `${idToken}`
        }
      })
      console.log(response)
      const user = response.data.data.getCurrentUser
      context.commit('setCurrentUser', user, { root: true })
    }
  }
}

<template>
  <div>
    <CContainer class="text-start bg-white shadow-sm py-5 px-4">
      <h1 class="display-4">
        Device Details
      </h1>
      <div class="text-secondary">Enter Device ID and click Lookup to begin</div>
      <br />
      <CRow>
        <CCol>
          <label>Device ID</label>
          <div class="d-flex">
            <div class="flex-grow-0" style="width: 300px">
              <CInputGroup>
                <CFormInput
                  type="text"
                  placeholder=""
                  :style="{ maxWidth: '300px' }"
                  :disabled="isDeviceLoading || updatingBalance"
                  v-model="selectedDeviceId"
                />
                <CButton type="button" color="primary" @click="loadDevice" :disabled="isDeviceLoading">
                  {{isDeviceLoading ? 'Loading...' : 'Lookup'}}
                </CButton>
              </CInputGroup>
            </div>
            <div class="flex-grow-1 ps-2 py-2" v-if="isDeviceLoading">
              <CProgress :value="deviceLoadingPercent">
                <CProgressBar :value="deviceLoadingPercent" class="fw-semibold overflow-visible px-2">{{ deviceLoadingStatus }}</CProgressBar>
              </CProgress>
            </div>
            <div class="flex-grow-1 ps-2" v-else>
              <CAlert class="px-2" style="padding-top: 8px; padding-bottom: 8px;" color="warning" v-if="deviceIdError">
                <font-awesome-icon icon="fa-solid fa-circle-exclamation" /> {{deviceIdError}}
              </CAlert>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow v-if="isDeviceLoaded" class="pt-5">
        <CCol>
          <CCard>
            <CCardHeader>
              Device Info
            </CCardHeader>
            <CCardBody>
              <div class="d-flex">
                <div class="flex-grow-1">
                  <CCardText>
                    Device ID&nbsp;
                    <TotBadge :copyValue="deviceIdText">{{deviceIdText}}</TotBadge>
                  </CCardText>
                  <CCardText>
                    Account ID&nbsp;
                    <TotBadge :copyValue="accountIdText">{{accountIdText}}</TotBadge>
                  </CCardText>
                </div>
                <!--<div>
                  <CDropdown color="primary" togglerText="Subscribe to Plan" :disabled="subscribingToPlan" style="width: 160px;">
                    <CDropdownToggle component="a" color="primary">Subscribe Plan</CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem
                        v-for="(planSubscription, key) in allAvailablePlans"
                        :key="key" href="#" @click="subscribeToPlan(planSubscription.planId)">{{ planSubscription.planName }}</CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </div>-->
              </div>
            </CCardBody>
          </CCard>
          <CRow class="pt-3">
            <CCol sm="5">
              <CCard style="min-height: 100%;" v-if="activePlanSubscriptions.length > 0">
                <CCardHeader>
                  Active Plans - {{ activePlanSubscriptionsCount }}
                </CCardHeader>
                <CListGroup flush>
                  <CListGroupItem v-for="activePlanSubscription in activePlanSubscriptions" :key="activePlanSubscription.planSubscriptionId">
                    <b>Plan Name:&nbsp;</b>
                    <a :href="`https://plan.totogidemos.com/plans/${activePlanSubscription.planVersion.plan.id}?versionId=${activePlanSubscription.planVersion.id}`" target="_blank">
                      {{ activePlanSubscription.planVersion.plan.name }}
                    </a>
                    <br />
                    <b>Plan Version:&nbsp;</b> {{ activePlanSubscription.planVersion.version }}
                    <br />
                    <b>Plan Subscription ID:&nbsp;</b> <TotBadge :copyValue="activePlanSubscription.planSubscriptionId">{{activePlanSubscription.planSubscriptionId}}</TotBadge>
                    <br />
                    <b>Starting Date:&nbsp;</b> {{ activePlanSubscription.fromText }}
                    <br />
                    <b>Ending Date:&nbsp;</b> {{ activePlanSubscription.toText }}
                    <br />
                    <span v-if="activePlanSubscription.overrides.length > 0">
                      <em class="text-primary tw-text-purple-600"><b>Overrides</b></em>
                      <br />
                      <span v-for="override in activePlanSubscription.overrides" :key="override.name">
                        <b>{{ override.name }}:&nbsp;</b> {{ override.value }}
                        <br />
                      </span>
                    </span>
                    <TotButton :loading="cancellingPlan" variant="outline" @click="cancelPlan(activePlanSubscription)" size="sm">
                      <template #initial>Cancel</template>
                      <template #loading>Cancel</template>
                    </TotButton>
                    <!--<b>Services:</b>
                    <ul>
                      <li v-for="planService in activePlanSubscription.planVersion.planServices" :key="planService.id">
                        {{ planService.name }} - <b>RG {{ planService.ratingGroupId }}</b> - <b>Balances</b>:&nbsp;
                        <span v-if="planService.balanceTypeIds && planService.balanceTypeIds.length > 0">{{ (planService.balanceTypeIds || []).join(', ') }}</span>
                        <span v-else>None</span>
                      </li>
                    </ul>-->
                  </CListGroupItem>
                </CListGroup>
              </CCard>
              <CCard style="min-height: 100%;" v-else>
                <CCardHeader>
                  Active Plans - {{ activePlanSubscriptionsCount }}
                </CCardHeader>
                <CCardBody>
                  <CCardTitle class="text-center">No active plans</CCardTitle>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol sm="7">
              <CCard style="min-height: 100%;" v-if="balances.length > 0">
                <CCardHeader>
                  Account Balances
                </CCardHeader>
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>
                        Name
                      </CTableHeaderCell>
                      <CTableHeaderCell>
                        Used
                      </CTableHeaderCell>
                      <CTableHeaderCell>
                        Available
                      </CTableHeaderCell>
                      <CTableHeaderCell>
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow v-for="(balance, idx) in balances" :key="idx">
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === balances.length - 1 }">
                        {{ balance.balanceType.name }}
                        &nbsp;
                        <TotBadge :copyValue="balance.balanceId">{{ balance.balanceId.substring(0, 8) }}</TotBadge>
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === balances.length - 1 }">
                        <span v-if="balance.balanceType.balanceTypeId === 'monetary'">{{currencySymbol}}</span>
                        <span v-if="balance.balanceType.balanceTypeId === 'monetary'">{{ Math.round(balance.used * 100) / 100 }}</span>
                        <span v-else>{{ balance.readable_used }}</span>
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === balances.length - 1 }">
                        <span v-if="balance.balanceType.balanceTypeId === 'monetary'">{{currencySymbol}}</span>
                        <span v-if="balance.balanceType.balanceTypeId === 'monetary'">{{ Math.round(balance.available * 100) / 100 }}</span>
                        <span v-else>{{ balance.readable_available }}</span>
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === balances.length - 1 }">
                        <CButton
                          size="sm"
                          shape="rounded-pill"
                          color="primary"
                          variant="outline"
                          :disabled="balance.isUnlimitedBalance || updatingBalance || isDeviceLoading"
                          @click="initiateUpdateBalance(balance)">
                          Update
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCard>
              <CCard style="min-height: 100%;" v-else>
                <CCardHeader>
                  Account Balances
                </CCardHeader>
                <CCardBody>
                  <CCardTitle class="text-center">No balances found</CCardTitle>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="pt-3">
            <CCol>
              <CCard v-if="voiceEDRs.length > 0">
                <CCardHeader>
                  VOICE
                </CCardHeader>
                <CListGroup flush>
                  <CListGroupItem v-for="(voiceEDR, key) in voiceEDRs" :key="key">
                    <CCardTitle class="pt-3">{{ voiceEDR.name }}</CCardTitle>
                    <CTable v-if="isMonetary">
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Time</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">From</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">To</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">Duration</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
                          <CTableHeaderCell class="text-end">Base Amt</CTableHeaderCell>
                          <CTableHeaderCell class="text-end">Tax Amt</CTableHeaderCell>
                          <CTableHeaderCell class="text-end">Total Amt</CTableHeaderCell>
                          <CTableHeaderCell class="text-end">Balance Amt</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        <CTableRow v-for="(edr, idx) in voiceEDR.edrs" :key="idx">
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1 }">
                            {{ edr.time }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.fromParty }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.toParty }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.totalTime }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.ratingGroup }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-end': true }">
                            {{ edr.base }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-end': true }">
                            {{ edr.tax }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-end': true }">
                            {{ edr.total }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-end': true }">
                            {{ edr.newBalanceValue }}
                          </CTableDataCell>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                    <CTable v-else>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Time</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">From</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">To</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">Duration</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">Balance</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        <CTableRow v-for="(edr, idx) in voiceEDR.edrs" :key="idx">
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1 }">
                            {{ edr.time }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.fromParty }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.toParty }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.totalTime }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.ratingGroup }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.newBalanceValue }}
                          </CTableDataCell>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                  </CListGroupItem>
                </CListGroup>
              </CCard>
              <CCard v-else>
                <CCardHeader>
                  VOICE
                </CCardHeader>
                <CCardBody>
                  <CCardTitle class="text-center">No data in last one week</CCardTitle>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="pt-3">
            <CCol>
              <CCard v-if="dataEDRs.length > 0">
                <CCardHeader>
                  DATA
                </CCardHeader>
                <CListGroup flush>
                  <CListGroupItem v-for="(dataEDR, key) in dataEDRs" :key="key">
                    <CCardTitle class="pt-3">{{ dataEDR.name }}</CCardTitle>
                    <CTable v-if="isMonetary">
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Time</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">Volume (MB)</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
                          <CTableHeaderCell class="text-end">Base Amt</CTableHeaderCell>
                          <CTableHeaderCell class="text-end">Tax Amt</CTableHeaderCell>
                          <CTableHeaderCell class="text-end">Total Amt</CTableHeaderCell>
                          <CTableHeaderCell class="text-end">Balance Amt</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        <CTableRow v-for="(edr, idx) in dataEDR.edrs" :key="idx">
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1 }">
                            {{ edr.time }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.volume }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.ratingGroup }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-end': true }">
                            {{ edr.base }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-end': true }">
                            {{ edr.tax }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-end': true }">
                            {{ edr.total }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-end': true }">
                            {{ edr.newBalanceValue }}
                          </CTableDataCell>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                    <CTable v-else>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Time</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">Volume (MB)</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
                          <CTableHeaderCell class="text-center">Balance (MB)</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        <CTableRow v-for="(edr, idx) in dataEDR.edrs" :key="idx">
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1 }">
                            {{ edr.time }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.volume }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.ratingGroup }}
                          </CTableDataCell>
                          <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
                            {{ edr.newBalanceValue }}
                          </CTableDataCell>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                  </CListGroupItem>
                </CListGroup>
              </CCard>
              <CCard v-else>
                <CCardHeader>
                  DATA
                </CCardHeader>
                <CCardBody>
                  <CCardTitle class="text-center">No data in last one week</CCardTitle>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="pt-3">
            <CCol>
              <CCard v-if="topUpHistory.length > 0">
                <CCardHeader>
                  TOPUPS
                </CCardHeader>
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>
                        Time
                      </CTableHeaderCell>
                      <CTableHeaderCell>
                        Adj Type
                      </CTableHeaderCell>
                      <CTableHeaderCell class="text-end">
                        Amount
                      </CTableHeaderCell>
                      <CTableHeaderCell class="text-end">
                        Balance Before
                      </CTableHeaderCell>
                      <CTableHeaderCell class="text-end">
                        Balance After
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow v-for="(topUp, idx) in topUpHistory" :key="idx">
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === topUpHistory.length - 1 }">
                        {{ topUp.time }}
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === topUpHistory.length - 1 }">
                        {{ topUp.adjustmentType }}
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === topUpHistory.length - 1, 'text-end': true }">
                        {{ topUp.amount }}
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === topUpHistory.length - 1, 'text-end': true }">
                        {{ topUp.balanceBefore }}
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === topUpHistory.length - 1, 'text-end': true }">
                        {{ topUp.balanceAfter }}
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCard>
              <CCard v-else>
                <CCardHeader>
                  TOPUPS
                </CCardHeader>
                <CCardBody>
                  <CCardTitle class="text-center">No topups found in last one week</CCardTitle>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <UpdateBalanceModal :balanceUnitType="balanceBeingUpdated?.balanceType?.unitType || defaultBalanceTypeUnitType" :showModal="showUpdateBalanceModal" :processingCreateAccountAction="updatingBalance" @modal-closed="updateBalanceModalClosed" @updateBalance="updateBalance"/>
    </CContainer>
  </div>
</template>

<script>
import { BALANCE_UNIT_TYPE_SERVICE_SPECIFIC, GRAPHQL_DEVICE_NOT_FOUND_ERROR_CODE } from '../utils/constants'
import moment from 'moment-timezone'
import { getReadableEntityValue } from '@/store/modules/utils/unifiedBalances'
import { axiosGraphQlRequest } from '@/utils/axiosInstances'
import { updateBalanceMutation } from '@/store/modules/utils/totogi/mutations'

import UpdateBalanceModal from './UpdateBalanceModal.vue'
import TotBadge from '@/components/common/tot-badge.vue'
import TotButton from './common/tot-button.vue'

const initialData = () => {
  return {
    showUpdateBalanceModal: false,
    updatingBalance: false,
    isDeviceLoading: false,
    isDeviceLoaded: false,
    deviceIdError: '',
    balanceBeingUpdated: null,
    selectedAccountId: null,
    selectedDeviceId: null,
    cancellingPlan: false,
    subscribingToPlan: false
  }
}

export default {
  name: 'DeviceDetails',
  components: { TotBadge, TotButton, UpdateBalanceModal },
  props: {
    deviceId: {
      type: String,
      required: false
    },
    accountId: {
      type: String,
      required: false
    }
  },
  data () {
    return initialData()
  },
  computed: {
    defaultBalanceTypeUnitType () {
      return BALANCE_UNIT_TYPE_SERVICE_SPECIFIC
    },
    allAvailablePlans () {
      return this.$store.state.allAvailablePlanInformation || []
    },
    accountIdText () {
      return this.$store.state.device.demoDevice.account?.id
    },
    deviceLoadingPercent () {
      return this.$store.state.device.loadingPercent
    },
    deviceLoadingStatus () {
      return this.$store.state.device.loadingStatus
    },
    balances () {
      return this.$store.state.device.demoDevice.account.balances.map(balance => {
        /* const isUnlimitedBalance = this.$store.state.device.demoAccount.activePlanVersions.filter(activePlanSubscription => {
          return activePlanSubscription.planVersion.template.services.filter(templateService => (templateService.managedBalance?.managedBalanceTypeId === balance.balanceType.balanceTypeId && templateService.managedBalance?.periodAllowance === null)).length > 0
        }).length > 0 */
        const isUnlimitedBalance = null
        const unlimitedText = isUnlimitedBalance ? 'Unlimited' : null
        const readableAvailable = unlimitedText || getReadableEntityValue(balance.balanceType.balanceTypeId, balance.available, balance.balanceType.unitType)
        return {
          ...balance,
          readable_available: readableAvailable,
          readable_total: getReadableEntityValue(balance.balanceType.balanceTypeId, balance.total, balance.balanceType.unitType),
          readable_used: getReadableEntityValue(balance.balanceType.balanceTypeId, balance.used, balance.balanceType.unitType),
          isUnlimitedBalance
        }
      })
    },
    deviceIdText () {
      return this.$store.state.device.demoDevice.id
    },
    activePlanSubscriptionsCount () {
      return (this.$store.state.device.demoDevice.account.activePlanVersions || []).length
    },
    activePlanSubscriptions () {
      return (this.$store.state.device.demoDevice.account.activePlanVersions || []).map(activePlanSubscription => {
        const isToTimeFar = moment(activePlanSubscription.to).diff(moment(), 'days') > 50000
        return {
          ...activePlanSubscription,
          fromText: moment(activePlanSubscription.from).tz(this.$store.state.appTimezone).format('YYYY-MM-DD hh:mm a'),
          toText: (isToTimeFar ? 'No end time' : moment(activePlanSubscription.to).tz(this.$store.state.appTimezone).format('YYYY-MM-DD hh:mm a'))
        }
      })
    },
    currencySymbol () {
      return this.$store.state.settings.currencySymbol
    },
    voiceEDRs () {
      const balanceTypesUsed = []
      const balanceTypeEDRs = {}
      const billingEDRs = this.$store.state.device.billingEDRs
      const voiceEDRs = billingEDRs.filter(edr => {
        return (
          edr.action === 'charge' &&
          edr.customData.charginginformation.multipleunitinformation.filter(mui => (this.$store.state.voiceRatingGroupBucket.indexOf(mui.ratinggroup) > -1)).length > 0
        )
      })
      voiceEDRs.forEach(voiceEDR => {
        const timeValue = moment(voiceEDR.createdAt).tz(this.$store.state.appTimezone)
        const timeString = timeValue.format('YYYY-MM-DD hh:mm a')
        const imsChargingInformation = voiceEDR.customData.charginginformation.imscharginginformation || {}
        const fromParty = imsChargingInformation.roleofimsnode === 'ORIGINATING' ? this.$store.state.device.demoDevice.id : (imsChargingInformation.calledpartyaddress || '')
        const toParty = imsChargingInformation.roleofimsnode === 'ORIGINATING' ? (imsChargingInformation.calledpartyaddress || '') : this.$store.state.device.demoDevice.id
        const multipleunitinformation = voiceEDR.customData.charginginformation.multipleunitinformation || []
        const edrLevelBalanceTypes = []
        const edrLevelBalanceTypeData = {}
        multipleunitinformation.forEach(mui => {
          const totalTime = mui.grantedunit?.time || 0
          const ratingGroup = mui.ratinggroup
          const muiLevelBalanceTypes = []
          const muiLevelBalanceTypeData = {}
          mui.charges.forEach(charge => {
            const balanceTypeId = charge.balanceused
            const tax = (Math.round(charge.chargedtax * 100) / 100)
            const total = (Math.round(charge.chargedtotal * 100) / 100)
            const base = (Math.round((total - tax) * 100) / 100)
            if (muiLevelBalanceTypeData[balanceTypeId]) {
              muiLevelBalanceTypeData[balanceTypeId].tax += tax
              muiLevelBalanceTypeData[balanceTypeId].total += total
              muiLevelBalanceTypeData[balanceTypeId].base += base
            } else {
              muiLevelBalanceTypes.push(balanceTypeId)
              muiLevelBalanceTypeData[balanceTypeId] = {
                tax,
                total,
                base
              }
            }
          })
          for (const muiLevelBalanceTypeId of muiLevelBalanceTypes) {
            if (edrLevelBalanceTypeData[muiLevelBalanceTypeId]) {
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].tax += muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].total += muiLevelBalanceTypeData[muiLevelBalanceTypeId].total
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].base += muiLevelBalanceTypeData[muiLevelBalanceTypeId].base
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].totalTime += totalTime
            } else {
              edrLevelBalanceTypes.push(muiLevelBalanceTypeId)
              edrLevelBalanceTypeData[muiLevelBalanceTypeId] = {
                tax: muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax,
                total: muiLevelBalanceTypeData[muiLevelBalanceTypeId].total,
                base: muiLevelBalanceTypeData[muiLevelBalanceTypeId].base,
                totalTime,
                ratingGroup
              }
            }
          }
        })
        for (const edrLevelBalanceTypeId of edrLevelBalanceTypes) {
          if (balanceTypeEDRs[edrLevelBalanceTypeId]) {
            balanceTypeEDRs[edrLevelBalanceTypeId].push({
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              totalTime: edrLevelBalanceTypeData[edrLevelBalanceTypeId].totalTime,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              fromParty,
              toParty,
              newBalanceValue: voiceEDR.customData.balances[edrLevelBalanceTypeId].map(balance => (balance.after?.available || 0)).reduce((sum, availableBalanceValue) => (sum || 0) + (isNaN(availableBalanceValue) ? 0 : Number(availableBalanceValue)))
            })
          } else {
            balanceTypesUsed.push(edrLevelBalanceTypeId)
            balanceTypeEDRs[edrLevelBalanceTypeId] = [{
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              totalTime: edrLevelBalanceTypeData[edrLevelBalanceTypeId].totalTime,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              fromParty,
              toParty,
              newBalanceValue: voiceEDR.customData.balances[edrLevelBalanceTypeId].map(balance => (balance.after?.available || 0)).reduce((sum, availableBalanceValue) => (sum || 0) + (isNaN(availableBalanceValue) ? 0 : Number(availableBalanceValue)))
            }]
          }
        }
      })
      return balanceTypesUsed.map(balanceTypeId => {
        console.log('Balance Type', balanceTypeId, this.$store.state.balanceTypes)
        const balanceType = this.$store.state.balanceTypes.find(balanceType => balanceType.balanceTypeId === balanceTypeId)
        const isMonetary = balanceType.balanceTypeId === 'monetary'
        const edrs = balanceTypeEDRs[balanceTypeId].map(edr => {
          return {
            totalTime: `${Math.floor(edr.totalTime / 60)} mins ${String(edr.totalTime % 60).padStart(2, '0')} secs`,
            time: edr.timeString,
            timeValue: edr.timeValue,
            ratingGroup: edr.ratingGroup,
            fromParty: edr.fromParty,
            toParty: edr.toParty,
            base: (isMonetary ? `${this.currencySymbol} ${(Math.round(edr.base * 100) / 100).toFixed(2)}` : ''),
            tax: (isMonetary ? `${this.currencySymbol} ${(Math.round(edr.tax * 100) / 100).toFixed(2)}` : ''),
            total: (isMonetary ? `${this.currencySymbol} ${(Math.round(edr.total * 100) / 100).toFixed(2)}` : `${Math.floor(edr.total / 60)} mins ${String(edr.total % 60).padStart(2, '0')} secs`),
            newBalanceValue: (isMonetary ? `${this.currencySymbol} ${(Math.round(edr.newBalanceValue * 100) / 100).toFixed(2)}` : `${Math.floor(edr.newBalanceValue / 60)} mins ${String(edr.newBalanceValue % 60).padStart(2, '0')} secs`)
          }
        })
        edrs.sort((a, b) => {
          return (a.timeValue.isAfter(b.timeValue) ? -1 : 1)
        })
        return {
          name: balanceType.name,
          isMonetary,
          edrs
        }
      })
    },
    dataEDRs () {
      const balanceTypesUsed = []
      const balanceTypeEDRs = {}
      const billingEDRs = this.$store.state.device.billingEDRs
      const dataEDRs = billingEDRs.filter(edr => {
        return (
          edr.action === 'charge' &&
          edr.customData.charginginformation.multipleunitinformation.filter(mui => (this.$store.state.dataRatingGroupBucket.indexOf(mui.ratinggroup) > -1)).length > 0
        )
      })
      dataEDRs.forEach(dataEDR => {
        const timeValue = moment(dataEDR.createdAt).tz(this.$store.state.appTimezone)
        const timeString = timeValue.format('YYYY-MM-DD hh:mm a')
        const multipleunitinformation = dataEDR.customData.charginginformation.multipleunitinformation || []
        const edrLevelBalanceTypes = []
        const edrLevelBalanceTypeData = {}
        multipleunitinformation.forEach(mui => {
          const totalVolume = mui.grantedunit?.totalvolume || 0
          const ratingGroup = mui.ratinggroup
          const muiLevelBalanceTypes = []
          const muiLevelBalanceTypeData = {}
          mui.charges.forEach(charge => {
            const balanceTypeId = charge.balanceused
            const tax = (Math.round(charge.chargedtax * 100) / 100)
            const total = (Math.round(charge.chargedtotal * 100) / 100)
            const base = (Math.round((total - tax) * 100) / 100)
            if (muiLevelBalanceTypeData[balanceTypeId]) {
              muiLevelBalanceTypeData[balanceTypeId].tax += tax
              muiLevelBalanceTypeData[balanceTypeId].total += total
              muiLevelBalanceTypeData[balanceTypeId].base += base
            } else {
              muiLevelBalanceTypes.push(balanceTypeId)
              muiLevelBalanceTypeData[balanceTypeId] = {
                tax,
                total,
                base
              }
            }
          })
          for (const muiLevelBalanceTypeId of muiLevelBalanceTypes) {
            if (edrLevelBalanceTypeData[muiLevelBalanceTypeId]) {
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].tax += muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].total += muiLevelBalanceTypeData[muiLevelBalanceTypeId].total
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].base += muiLevelBalanceTypeData[muiLevelBalanceTypeId].base
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].totalVolume += totalVolume
            } else {
              edrLevelBalanceTypes.push(muiLevelBalanceTypeId)
              edrLevelBalanceTypeData[muiLevelBalanceTypeId] = {
                tax: muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax,
                total: muiLevelBalanceTypeData[muiLevelBalanceTypeId].total,
                base: muiLevelBalanceTypeData[muiLevelBalanceTypeId].base,
                totalVolume,
                ratingGroup
              }
            }
          }
        })
        for (const edrLevelBalanceTypeId of edrLevelBalanceTypes) {
          if (balanceTypeEDRs[edrLevelBalanceTypeId]) {
            balanceTypeEDRs[edrLevelBalanceTypeId].push({
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              totalVolume: edrLevelBalanceTypeData[edrLevelBalanceTypeId].totalVolume,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              newBalanceValue: dataEDR.customData.balances[edrLevelBalanceTypeId].map(balance => (balance.after?.available || 0)).reduce((sum, availableBalanceValue) => (sum || 0) + (isNaN(availableBalanceValue) ? 0 : Number(availableBalanceValue)))
            })
          } else {
            balanceTypesUsed.push(edrLevelBalanceTypeId)
            balanceTypeEDRs[edrLevelBalanceTypeId] = [{
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              totalVolume: edrLevelBalanceTypeData[edrLevelBalanceTypeId].totalVolume,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              newBalanceValue: dataEDR.customData.balances[edrLevelBalanceTypeId].map(balance => (balance.after?.available || 0)).reduce((sum, availableBalanceValue) => (sum || 0) + (isNaN(availableBalanceValue) ? 0 : Number(availableBalanceValue)))
            }]
          }
        }
      })
      return balanceTypesUsed.map(balanceTypeId => {
        const balanceType = this.$store.state.balanceTypes.find(balanceType => balanceType.balanceTypeId === balanceTypeId)
        const isMonetary = balanceType.balanceTypeId === 'monetary'
        const edrs = balanceTypeEDRs[balanceTypeId].map(edr => {
          return {
            volume: `${Math.round((edr.totalVolume / (1024 * 1024)) * 100) / 100} MB`,
            time: edr.timeString,
            timeValue: edr.timeValue,
            ratingGroup: edr.ratingGroup,
            base: (isMonetary ? `${this.currencySymbol} ${(Math.round(edr.base * 100) / 100).toFixed(2)}` : ''),
            tax: (isMonetary ? `${this.currencySymbol} ${(Math.round(edr.tax * 100) / 100).toFixed(2)}` : ''),
            total: (isMonetary ? `${this.currencySymbol} ${(Math.round(edr.total * 100) / 100).toFixed(2)}` : `${Math.round((edr.totalVolume / (1024 * 1024)) * 100) / 100} MB`),
            newBalanceValue: (isMonetary ? `${this.currencySymbol} ${(Math.round(edr.newBalanceValue * 100) / 100).toFixed(2)}` : `${Math.round((edr.newBalanceValue / (1024 * 1024)) * 100) / 100} MB`)
          }
        })
        edrs.sort((a, b) => {
          return (a.timeValue.isAfter(b.timeValue) ? -1 : 1)
        })
        return {
          name: balanceType.name,
          isMonetary,
          edrs
        }
      })
    },
    topUpHistory () {
      const accountEDRs = this.$store.state.device.accountEDRs
      const updateBalanceEDRs = accountEDRs.filter(edr => {
        return (
          edr.action === 'updateBalance'
        )
      })
      return updateBalanceEDRs.map(updateBalanceEDR => {
        const balanceType = this.balances.find(balance => balance.balanceType.balanceTypeId === updateBalanceEDR.customData.balancetypeid)
        const unitType = balanceType?.balanceType?.unitType
        updateBalanceEDR.customData.balancebefore = isNaN(updateBalanceEDR.customData.balancebefore) ? 0 : Number(updateBalanceEDR.customData.balancebefore)
        updateBalanceEDR.customData.balanceafter = isNaN(updateBalanceEDR.customData.balanceafter) ? 0 : Number(updateBalanceEDR.customData.balanceafter)
        const amount = (updateBalanceEDR.customData.adjustment === 'SET' ? (updateBalanceEDR.customData.balanceafter || 0) : ((updateBalanceEDR.customData.balanceafter || 0) - (updateBalanceEDR.customData.balancebefore || 0)))
        return {
          time: moment(updateBalanceEDR.createdAt).tz(this.$store.state.appTimezone).format('YYYY-MM-DD hh:mm a'),
          adjustmentType: updateBalanceEDR.customData.adjustment,
          amount: `${updateBalanceEDR.customData.balancetypeid === 'monetary' ? `${this.currencySymbol} ` : ''}${getReadableEntityValue(updateBalanceEDR.customData.balancetypeid, amount, unitType)}`,
          balanceBefore: `${updateBalanceEDR.customData.balancetypeid === 'monetary' ? `${this.currencySymbol} ` : ''}${getReadableEntityValue(updateBalanceEDR.customData.balancetypeid, updateBalanceEDR.customData.balancebefore || 0, unitType)}`,
          balanceAfter: `${updateBalanceEDR.customData.balancetypeid === 'monetary' ? `${this.currencySymbol} ` : ''}${getReadableEntityValue(updateBalanceEDR.customData.balancetypeid, updateBalanceEDR.customData.balanceafter || 0, unitType)}`
        }
      })
    }
  },
  mounted () {
    this.initializeComponent()
  },
  beforeRouteUpdate (to, from) {
    console.log('Router updae', to, from)
    this.initializeComponent()
  },
  beforeRouteLeave (to, from) {
    console.log('leave')
    this.reset()
  },
  methods: {
    async initializeComponent () {
      if (this.deviceId) {
        this.selectedAccountId = this.accountId
        this.selectedDeviceId = this.deviceId
        this.loadDevice()
        if (!this.allAvailablePlans || !this.allAvailablePlans.length) {
          await this.$store.dispatch('planVersions/getPlanInformation')
        }
      }
    },
    async cancelPlan (activePlanSubscription) {
      this.cancellingPlan = true
      await this.$store.dispatch('device/cancelPlanSubscription', {
        planSubscriptionId: activePlanSubscription.planSubscriptionId,
        planVersionId: activePlanSubscription.planVersion.id,
        performRefresh: false
      })
      const accountId = this.$store.state.device.demoDevice.account.id
      const deviceId = this.$store.state.device.demoDevice.id
      this.reset()
      this.selectedAccountId = accountId
      this.selectedDeviceId = deviceId
      this.loadDevice()
      this.cancellingPlan = false
    },
    async subscribeToPlan (planId) {
      if (this.subscribingToPlan) {
        return
      }
      this.subscribingToPlan = true
      await this.$store.dispatch('account/subscribeToCurrentPlanVersion', {
        accountId: this.$store.state.device.demoDevice.account.id,
        planId
      })
      const accountId = this.$store.state.device.demoDevice.account.id
      const deviceId = this.$store.state.device.demoDevice.id
      this.reset()
      this.selectedAccountId = accountId
      this.selectedDeviceId = deviceId
      this.loadDevice()
      this.subscribingToPlan = false
    },
    updateBalanceModalClosed () {
      this.showUpdateBalanceModal = false
      this.updatingBalance = false
    },
    async loadDevice () {
      if (!this.selectedDeviceId) {
        this.deviceIdError = 'Please enter the device ID'
        return
      }
      this.isDeviceLoading = true
      this.deviceIdError = ''
      try {
        await this.$store.dispatch('device/getDeviceDetails', { deviceId: String(this.selectedDeviceId).trim() })
      } catch (err) {
        if (err.errorCode && err.errorCode === GRAPHQL_DEVICE_NOT_FOUND_ERROR_CODE) {
          this.deviceIdError = 'Device not found, please check the ID'
          this.isDeviceLoading = false
          return
        }
      }
      await this.$nextTick()
      await this.$nextTick()
      this.isDeviceLoading = false
      this.deviceIdError = false
      this.selectedDeviceId = ''
      this.isDeviceLoaded = true
    },
    reset () {
      this.$store.dispatch('device/resetDemoDevice')
      if (this.$store.state.deviceLogs && this.$store.state.deviceLogs[this.selectedDeviceId]) {
        this.$store.state.deviceLogs[this.selectedDeviceId] = []
      }
      this.showUpdateBalanceModal = false
      this.updatingBalance = false
      this.selectedDeviceId = ''
      this.isDeviceLoading = false
      this.isDeviceLoaded = false
      this.deviceIdError = ''
      this.updatingBalance = false
      this.balanceBeingUpdated = null
      this.selectedAccountId = null
      this.selectedDeviceId = null
      this.cancellingPlan = false
      this.$store.dispatch('resetEdrs')
    },
    initiateUpdateBalance (balance) {
      this.showUpdateBalanceModal = true
      this.updatingBalance = false
      this.balanceBeingUpdated = balance
    },
    async updateBalance (balanceChanges) {
      const balanceId = this.balanceBeingUpdated.balanceId
      const balanceTypeId = this.balanceBeingUpdated.balanceType.balanceTypeId
      const newBalanceValue = balanceChanges.newBalanceValue
      const adjustmentType = (['CREDIT', 'DEBIT', 'SET'].indexOf(balanceChanges.adjustmentType) > -1 ? balanceChanges.adjustmentType : 'SET')
      if (!newBalanceValue || isNaN(newBalanceValue)) {
        this.entityInputModalError = 'Please enter a valid amount'
        return
      }
      this.updatingBalance = true
      this.entityInputModalProcessing = true
      await axiosGraphQlRequest(
        this.$store.state.awsRegionInfo.aws_appsync_graphqlEndpoint,
        updateBalanceMutation,
        {
          input: {
            balanceId,
            providerId: this.$store.state.providerId,
            accountId: this.$store.state.device.demoDevice.account.id,
            balanceInfo: {
              balanceTypeId,
              balanceValue: newBalanceValue,
              adjustment: adjustmentType
            }
          }
        },
        this.$store.getters.graphQLHeaders
      )
      this.updatingBalance = false
      this.isDeviceLoading = true
      this.balanceBeingUpdated = null
      this.showUpdateBalanceModal = false
      await this.$store.dispatch('device/getDeviceDetails', { deviceId: this.$store.state.device.demoDevice.id })
      this.isDeviceLoading = false
      balanceChanges.callback()
    }
  }
}
</script>

<style>
.balances-table-last-row {
  border-bottom:  none !important;
}
</style>

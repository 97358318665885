<template>
<div>
  <CNavbar
    expand="lg"
    color-scheme="dark"
    class="main-navbar-background py-0"
    placement="fixed-top"
    style="position: relative;"
  >
    <!-- ^ Same background color as the Totogi documentation sidebar -->
    <!-- http://docs.api.totogi.com/graphql/#definition-InitialTemplateInstance -->
    <CContainer fluid>
      <CNavbarBrand href="#" class="py-2">
        <img
          src="@/assets/totogi-logo-white.svg"
          alt="Totogi Logo"
          style="max-width: 80px"
          class="d-inline-block align-top"
        />
      </CNavbarBrand>
      <CNavbarNav class="h-100" style="position: relative;">
          <template v-if="signedIn">
            <CNavItem :class="plansLinkClasses" style="box-sizing: border-box">
              <CNavLink
                @click="this.$router.push({ name: 'Plans' })"
                :class="{ active: this.isPlansPage }"
                href="#"
              >
                Plans
              </CNavLink>
            </CNavItem>
            <CNavItem :class="accountsLinkClasses" style="box-sizing: border-box">
              <CNavLink
                @click="this.$router.push({ name: 'Accounts' })"
                :class="{ active: this.isAccountsPage }"
                href="#"
              >
                Accounts
              </CNavLink>
            </CNavItem>
            <CNavItem :class="demoDeviceLinkClasses" style="box-sizing: border-box">
              <CNavLink
                @click="this.$router.push({ name: 'DeviceDemo' })"
                href="#"
              >
                Device Demo
              </CNavLink>
            </CNavItem>
            <CNavItem :class="inactiveLinkClasses" style="box-sizing: border-box">
              <CNavLink
                v-if="$store.state.idToken"
                href="#"
                @click="openConfigModal()"
              >
                Settings
              </CNavLink>
            </CNavItem>
            <CNavItem :class="profileLinkClasses" style="box-sizing: border-box">
              <CNavLink
                @click="this.$router.push({ name: 'Profile' })"
                :class="{ active: this.isProfilePage }"
                href="#"
              >
                My Profile
              </CNavLink>
            </CNavItem>
            <CNavItem :class="inactiveLinkClasses" style="box-sizing: border-box">
              <CNavLink
                :class="{ active: this.isProfilePage }"
                href="#"
                @click="signOut"
              >
                Logout
              </CNavLink>
            </CNavItem>
          </template>
      </CNavbarNav>
    </CContainer>
  </CNavbar>
  <CModal
    :backdrop="true"
    :centered="true"
    alignment="center"
    :visible="configModalVisible"
    @close="handleConfigModalClose">
    <CModalHeader>
      <CModalTitle>Current Demo Tool Config</CModalTitle>
    </CModalHeader>
    <CModalBody style="text-align: left;">
      <ProviderConfig />
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeConfigModal()">
        Close
      </CButton>
    </CModalFooter>
  </CModal>
</div>
</template>

<script>
import ProviderConfig from './ProviderConfig.vue'
import { DEFAULT_AWS_REGION } from '../utils/constants'

export default {
  name: 'MainNav',
  components: { ProviderConfig },
  data () {
    return {
      configModalVisible: false,
      navItemClasses: 'py-1 px-3 border-bottom-4',
      windowMessageEvent: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.windowMessageEvent) {
        console.log('Setting sign out event')
        vm.windowMessageEvent = window.addEventListener('message', (event) => {
          console.log('Received message from window', event.data)
          const { type } = event.data
          if (type === 'kiosk.logout') {
            this.signOut()
          }
        })
      }
    })
  },
  mounted () {
    if (!this.windowMessageEvent) {
      console.log('Setting Kiosk sign out event')
      this.windowMessageEvent = window.addEventListener('message', (event) => {
        const { type } = event.data
        if (type === 'kiosk.logout') {
          console.log('Received Kiosk Logout, signing out')
          this.signOut()
        }
      })
    }
    if (!this.$store.state.dispatchedProviderConfig) {
      console.log('Dispatching my provider config load')
      this.$store.dispatch('myProviderConfig/getMyProviderConfig')
      this.$store.commit('setDispatchedProviderConfig', true)
    }
  },
  beforeRouteLeave () {
    console.log('Main nav leave')
    if (this.windowMessageEvent) {
      console.log('Clearing Kiosk sign out event')
      window.removeEventListener(this.windowMessageEvent)
      this.windowMessageEvent = null
    }
  },
  computed: {
    accountsLinkClasses () {
      return this.isAccountsPage ? this.activeLinkClasses : this.inactiveLinkClasses
    },
    activeLinkClasses () {
      return `${this.navItemClasses} active-link-border`
    },
    demoDeviceLinkClasses () {
      return this.isDeviceDemoPage ? this.activeLinkClasses : this.inactiveLinkClasses
    },
    inactiveLinkClasses () {
      return `${this.navItemClasses} inactive-link-border`
    },
    isAccountsPage () {
      return (this.$router.currentRoute.value.name === 'Home' || this.$router.currentRoute.value.name === 'Accounts')
    },
    isDeviceDemoPage () {
      return this.$router.currentRoute.value.name === 'DeviceDemo'
    },
    isPlansPage () {
      return this.$router.currentRoute.value.name === 'Plans'
    },
    isProfilePage () {
      return this.$router.currentRoute.value.name === 'Profile'
    },
    plansLinkClasses () {
      return this.isPlansPage ? this.activeLinkClasses : this.inactiveLinkClasses
    },
    profileLinkClasses () {
      return this.isProfilePage ? this.activeLinkClasses : this.inactiveLinkClasses
    },
    signedIn: function () {
      return this.$store.state.providerId
    }
  },
  methods: {
    openConfigModal () {
      this.configModalVisible = true
    },
    closeConfigModal () {
      this.configModalVisible = false
    },
    handleConfigModalClose () {
      this.configModalVisible = false
    },
    signOut () {
      this.$store.commit('setIdToken', null)
      this.$store.commit('setProviderId', null)
      this.$cookies.set('idToken', '')
      this.$cookies.set('providerId', '')
      this.$store.dispatch('resetAll')
      const awsRegionName = this.$cookies.get('awsRegionName') || DEFAULT_AWS_REGION
      this.$store.commit('setAWSRegionInfo', awsRegionName)
      this.$router.push({ name: 'SignIn' })
    }
  }
}
</script>

<style>
.main-navbar-background {
  background-color: #001D3D;
}

.active-link-border {
  border-bottom: solid;
  border-color: #FF4F58;
}

.inactive-link-border {
  border-bottom: solid;
  border-color: rgba(0, 0, 0, 0);
}
</style>

<template>
  <div>
    <MainNav />
    <DeviceDetails :accountId="accountId" :deviceId="deviceId" />
  </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue'
import DeviceDetails from '@/components/DeviceDetails.vue'

export default {
  name: 'AccountsView',
  components: {
    MainNav,
    DeviceDetails
  },
  props: {
    deviceId: {
      type: String,
      required: false
    },
    accountId: {
      type: String,
      required: false
    }
  }
}
</script>

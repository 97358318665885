<template>
<div class="d-flex gap-2">
  <div class="flex-grow-1 pb-3">
    <CRow>
      <CCol>
        <CFormLabel>Override At</CFormLabel>
        <CFormSelect v-model="overrideAt">
          <option value="Plan">Plan Level</option>
          <option v-for="serviceName of serviceNames" :key="serviceName.value" :value="serviceName.value">{{ serviceName.label }}</option>
        </CFormSelect>
      </CCol>
    </CRow>
    <CRow class="gap-2">
      <CCol>
        <CFormLabel>Name</CFormLabel>
        <CFormSelect v-model="overrideName">
          <option v-for="availableOverride of filteredAvailablePlanOverrides" :key="availableOverride.field" :value="availableOverride.field">{{ availableOverride.field }}</option>
        </CFormSelect>
      </CCol>
      <CCol>
        <CFormLabel>Value</CFormLabel>
        <CFormSelect v-model="overrideValue" v-if="selectedOverride && selectedOverride.values">
          <option v-for="value of selectedOverride.values" :key="value" :value="value">{{ value }}</option>
        </CFormSelect>
        <CFormInput v-model="overrideValue" :type="fieldHtmlType" v-else />
      </CCol>
    </CRow>
  </div>
  <div class="pb-3">
    <CFormLabel class="invisible">Inv</CFormLabel>
    <br />
    <TotButton @button-click="removeOverride">
      <template #initial>
        <font-awesome-icon icon="fa-solid fa-trash-can" />
      </template>
    </TotButton>
  </div>
</div>
</template>

<script>
import { AVAILABLE_PLAN_OVERRIDES } from '@/utils/constants'
import TotButton from './common/tot-button.vue'

export default {
  components: { TotButton },
  computed: {
    allAvailablePlans () {
      return this.$store.state.allAvailablePlanInformation || []
    },
    filteredAvailablePlanOverrides () {
      return this.availablePlanOverrides.filter(override => {
        if (!override.scope) {
          return true
        }

        return ((this.overrideAt === 'Plan' && override.scope === 'Plan') || (this.overrideAt !== 'Plan' && override.scope === 'Plan Service'))
      })
    },
    fieldHtmlType () {
      switch (this.selectedOverride?.type || 'STRING') {
        case 'NUMBER':
          return 'number'
        case 'STRING':
        case 'JSON':
        default:
          return 'text'
      }
    },
    serviceNames () {
      const planVersion = this.$store.state.planVersions.planVersions[this.selectedPlanVersionId]
      if (planVersion) {
        return planVersion.planServices
          .filter(planService => planService.name.indexOf('Monetary') < 0)
          .map(planService => {
            return {
              label: `${planService.name} - ${String(planService.balanceName).split('.')[0]}`,
              value: planService.name
            }
          })
      }
      return []
    }
  },
  data () {
    return {
      availablePlanOverrides: [{ field: 'No Selection' }, ...AVAILABLE_PLAN_OVERRIDES],
      overrideObj: {},
      overrideAt: 'Plan',
      overrideName: null,
      overrideValue: null,
      selectedOverride: null
    }
  },
  emits: ['removeOverride', 'overrideUpdated'],
  methods: {
    overrideAtChanged () {
      if (this.overrideAt === 'Plan') {
        this.overrideObj.name = this.overrideName
      } else {
        this.overrideObj.name = this.overrideAt
      }
      this.$emit('overrideUpdated')
    },
    overrideNameChanged () {
      this.overrideObj.value = null
      this.overrideValue = null
      for (const availableOverride of this.availablePlanOverrides) {
        if (availableOverride.field === this.overrideName) {
          this.selectedOverride = availableOverride
          if (this.selectedOverride.values) {
            this.overrideObj.value = this.selectedOverride.values[0]
          }
          break
        }
        this.selectedOverride = null
      }
      this.overrideAtChanged()
    },
    overrideValueChanged () {
      if (this.overrideAt === 'Plan') {
        this.overrideObj.value = this.overrideValue
      } else {
        this.overrideObj.value = JSON.stringify({ [this.overrideName]: this.overrideValue })
      }
      this.$emit('overrideUpdated')
    },
    removeOverride () {
      this.$emit('removeOverride')
    }
  },
  mounted () {
    this.overrideObj = this.override
    this.overrideName = this.overrideObj.name
    this.overridValue = this.overrideObj.value
    this.overrideNameChanged()
  },
  props: {
    override: {
      required: true
    },
    selectedPlanVersionId: {
      required: false,
      default: null
    }
  },
  watch: {
    overrideAt () {
      this.overrideAtChanged()
    },
    overrideName (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.overrideNameChanged()
      }
    },
    overrideValue (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.overrideValueChanged()
      }
    }
  }
}
</script>

<template>
<div class="iphone-x phone-demo-container">
  <i>Speaker</i>
  <b>Camera</b>
  <br><br><br>
  <CRow>
    <CFormInput :modelValue="callednumber" @input="$emit('update:callednumber', $event.target.value)" type="text" placeholder="Enter B Number" filled rounded dense aria-label="callednumber"/>
    <div class="position: relative !important; width: 0px; height: 0px;">
      <CToaster style="position: absolute !important; left: 0px; top: 0px;">
        <CToast color="warning" :autohide="true" :delay="3000" :key="index" v-for="(toast, index) in phoneRequiredToasts">
          <CToastBody>B Phone number is required.</CToastBody>
        </CToast>
      </CToaster>
    </div>
    <CCol>
      <PhoneIcon :numberMinutes="minuteSelection" :deviceId="deviceId" :callednumber="callednumber" @phone-number-required="phoneNumberRequired"/>
      <div id="smsVoiceDropDownSelect">
        <CFormSelect
          aria-label="Select number of minutes to charge"
          @change="$emit('changeMinute', $event.target.value)"
          :value="minuteSelection"
        >
          <option value="5">5 Minutes</option>
          <option value="25">25 Minutes</option>
          <option value="100">100 Minutes</option>
          <option value="250">250 Minutes</option>
        </CFormSelect>
      </div>
    </CCol>
    <CCol>
      <MessagesIcon :numberSms="smsSelection" :deviceId="deviceId" :callednumber="callednumber" @phone-number-required="phoneNumberRequired"/>
      <div id="smsVoiceDropDownSelect">
        <CFormSelect
          aria-label="Select number of SMS to charge"
          @change="$emit('changeSms', $event.target.value)"
          :value="smsSelection"
        >
          <option value="5">5 SMS</option>
          <option value="25">25 SMS</option>
          <option value="100">100 SMS</option>
          <option value="250">250 SMS</option>
        </CFormSelect>
      </div>
    </CCol>
  </CRow>
  <hr>
  <h3>Data Usage:</h3>
  <CRow>
    <CCol>
      <NetflixIcon :deviceId="deviceId" />
      <h4>2hr episode</h4>
      <h4>(2GB)</h4>
    </CCol>
    <CCol>
      <PodcastIcon :deviceId="deviceId" />
      <h4>1.5hr podcast</h4>
      <h4>(100MB)</h4>
    </CCol>
    <CCol>
      <InstagramIcon :deviceId="deviceId" />
      <h4>30min social</h4>
      <h4>(50MB)</h4>
    </CCol>
  </CRow>
  <CRow>
    <CCol>
      <WhatsAppIcon :deviceId="deviceId" />
      <h4>1hr call</h4>
      <h4>(50MB)</h4>
    </CCol>
    <CCol />
    <CCol>
      <FlexDataIcon :ratingGroup="flex1RG" name="Control" :megabytes="flex1Vol" :deviceId="deviceId"/>
      <div id="flex1RG">
        <CFormInput
          v-model="flex1RG"
          type="text" placeholder="Rating Group"
          filled rounded dense aria-label="callednumber"/>
      </div>
      <div id="flex1Vol">
        <CFormInput
          v-model="flex1Vol"
          type="text" placeholder="Megabytes"
          filled rounded dense aria-label="callednumber"/>
      </div>
    </CCol>
  </CRow>
  <div
    v-if="$store.state.zeroRatedData"
  >
    <hr>
    <CRow>
      <h3>Zero-Rated Data Usage:</h3>
      <CCol>
        <WhatsAppIcon />
        <h4>WhatsApp (50MB)</h4>
      </CCol>
      <CCol>
        <FacebookIcon :deviceId="deviceId" />
        <h4>Facebook (50MB)</h4>
      </CCol>
    </CRow>
  </div>
  <span>Left action button</span>
  <span>Right action button</span>
  <br>
</div>
</template>

<script>
import FacebookIcon from '@/components/icons/FacebookIcon.vue'
import WhatsAppIcon from '@/components/icons/WhatsAppIcon.vue'
import NetflixIcon from '@/components/icons/NetflixIcon.vue'
import MessagesIcon from '@/components/icons/MessagesIcon.vue'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'
import PodcastIcon from '@/components/icons/PodcastIcon.vue'
import InstagramIcon from '@/components/icons/InstagramIcon.vue'
import FlexDataIcon from '@/components/icons/FlexDataIcon.vue'
import { CFormInput } from '@coreui/vue'

export default {
  data () {
    return {
      phoneRequiredToasts: [],
      flex1RG: '',
      flex1Vol: ''
    }
  },
  components: {
    FacebookIcon,
    WhatsAppIcon,
    NetflixIcon,
    MessagesIcon,
    PhoneIcon,
    PodcastIcon,
    InstagramIcon,
    FlexDataIcon,
    CFormInput
  },
  computed: {
    isOnNetMode: {
      get () {
        return this.$store.state.settings.isOnNetMode
      },
      set (newValue) {
        this.$store.dispatch('settings/updateIsOnNetMode', { isOnNetMode: newValue })
      }
    }
  },
  props: {
    callednumber: String,
    deviceId: {
      type: String,
      required: true
    },
    minuteSelection: Number,
    smsSelection: Number
  },
  emits: ['changeMinute', 'changeSms'],
  methods: {
    phoneNumberRequired () {
      this.phoneRequiredToasts.push('test')
    }
  }
}
</script>

<style>
#callednumber {
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

#smsVoiceDropDownSelect {
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.row {
  --bs-gutter-x: 1 rem !important;
  --cui-gutter-x: 1 rem !important;
}

.iphone-x {
  position: relative;
  margin: 40px auto;
  width: 360px;
  height: 780px;
  background-color: #7371ee;
  background-image: linear-gradient(60deg, #7371ee 1%, #a1d9d6 100%);
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
}
.iphone-x:before, .iphone-x:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.iphone-x:after {
  bottom: 7px;
  width: 140px;
  height: 4px;
  background-color: #f2f2f2;
  border-radius: 10px;
}
.iphone-x:before {
  top: 0px;
  width: 56%;
  height: 30px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}
.iphone-x i, .iphone-x b, .iphone-x s, .iphone-x span {
  position: absolute;
  display: block;
  color: transparent;
}
.iphone-x i {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(255, 255, 255, 0.2);
}
.iphone-x b {
  left: 10%;
  top: 0px;
  transform: translate(180px, 4px);
  width: 12px;
  height: 12px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(255, 255, 255, 0.2);
}
.iphone-x b:after {
  content: '';
  position: absolute;
  background-color: #2d4d76;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
  top: 3px;
  left: 3px;
  display: block;
  border-radius: 4px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}
.iphone-x s {
  top: 50px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  width: 100%;
  font-size: 70px;
  font-weight: 100;
  padding-top: 60px;
}
.iphone-x span {
  bottom: 50px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  left: 30px;
}
.iphone-x span + span {
  left: auto;
  right: 30px;
}
.form-switch {
  min-height: 38px !important;
}
.form-switch .form-check-input {
  width: 50px !important;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}
.form-switch .form-label {
  margin-top: 7px !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  margin-bottom: 7px !important;
}
.b-number-net- {
  line-height: 10px;
  font-size: 10px;
}
.phone-demo-container .form-switch {
  max-width: 50px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
</style>

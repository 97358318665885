<template>
<CModal
  :backdrop="true"
  :centered="true"
  alignment="center"
  :visible="showModal"
  @close="handleModalClose"
>
  <CModalHeader>
    <CModalTitle>
      Select a plan
    </CModalTitle>
  </CModalHeader>
  <CModalBody class="text-start">
    <h5>Plan</h5>
    <CFormSelect v-model="selectedPlanVersionId">
      <option v-for="planInfo of allAvailablePlans" :key="planInfo.planVersionId" :value="planInfo.planVersionId">{{ planInfo.planName }}</option>
    </CFormSelect>
    <div class="py-3"></div>
    <h5>
      Overrides&nbsp;
      <TotButton @button-click="addOverride" size="sm">
        <template #initial>
          <font-awesome-icon icon="fa-solid fa-plus" />
        </template>
      </TotButton>
    </h5>
    <PlanOverrideField :selectedPlanVersionId="selectedPlanVersionId" v-for="(override, idx) in overrides" @remove-override="removeOverride(idx)" :override="override" :key="idx" @override-updated="overrideUpdated" />
  </CModalBody>
  <CModalFooter>
    <TotButton @button-click="selectPlan">
      <template #initial>
        Select Plan
      </template>
    </TotButton>
  </CModalFooter>
</CModal>
</template>

<script>
import TotButton from './common/tot-button.vue'
import PlanOverrideField from './PlanOverrideField.vue'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedPlanVersionId: null,
      overrides: []
    }
  },
  components: { PlanOverrideField, TotButton },
  computed: {
    allAvailablePlans () {
      return this.$store.state.allAvailablePlanInformation || []
    }
  },
  emits: ['planSelected', 'overridesChanged', 'modalClosed'],
  mounted () {
    this.initializePlanSelector()
  },
  methods: {
    async initializePlanSelector () {
      console.log('Loading plan selector Modal')
      console.log(!this.allAvailablePlans, this.allAvailablePlans, this.allAvailablePlans.length)
      if (!this.allAvailablePlans || !this.allAvailablePlans.length) {
        await this.$store.dispatch('planVersions/getPlanInformation')
      }
      if (
        this.$store.state.currentPlanVersionId &&
        this.allAvailablePlans &&
        this.allAvailablePlans.length > 0 &&
        this.allAvailablePlans.filter(plan => plan.planVersionId === this.$store.state.currentPlanVersionId).length
      ) {
        this.selectedPlanVersionId = this.$store.state.currentPlanVersionId
      } else if (this.allAvailablePlans && this.allAvailablePlans.length > 0) {
        this.selectedPlanVersionId = this.allAvailablePlans[0].planVersionId
      } else {
        console.log(this.$store.state.currentPlanVersionId)
        console.log(this.allAvailablePlans)
        console.log(this.$store.state.allAvailablePlanInformation)
      }
    },
    addOverride () {
      this.overrides.push({})
    },
    removeOverride (idx) {
      this.overrides.splice(idx, 1)
    },
    overrideUpdated () {
      this.$emit('overridesChanged', this.overrides)
    },
    overridesUpdated () {
      this.overrideUpdated()
    },
    handleModalClose () {
      this.$emit('modalClosed')
    },
    selectPlan () {
      console.log('Selected plan', this.selectedPlanVersionId)
      this.$emit('planSelected', this.selectedPlanVersionId)
    }
  },
  watch: {
    selectedPlanVersionId () {
      this.overrides = []
      this.overridesUpdated()
      if (!this.$store.state.planVersions.planVersions[this.selectedPlanVersionId]) {
        this.$store.dispatch('planVersions/loadPlanVersion', { planVersionId: this.selectedPlanVersionId })
      }
    }
  }
}
</script>

export const initialState = () => {
  return {}
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    }
  },
  actions: {
    async toggleVerbosity (context) {
      try {
        context.commit('toggleVerboseLogging', null, { root: true })
      } catch (error) {
        console.log(error)
      }
    }
  }
}

<template>
  <FrontPage>
    <SignIn />
  </FrontPage>
</template>

<script>
import FrontPage from '../components/FrontPage'
import SignIn from '@/components/SignIn'

export default {
  name: 'SignInView',
  components: { FrontPage, SignIn }
}
</script>

<template>
<CModal
  :backdrop="true"
  :centered="true"
  alignment="center"
  :visible="showModal"
  @close="handleModalClose"
>
  <CModalHeader>
    <CModalTitle>
      Update Balance
    </CModalTitle>
  </CModalHeader>
  <CModalBody class="text-start">
    <CRow>
      <CCol class="py-2">
        <CFormLabel>New Balance Value</CFormLabel>
        <CInputGroup>
          <CFormInput
            :disabled="processingUpdateBalanceAction"
            type="text"
            placeholder="New Balance Value"
            v-model="newBalanceValue"/>
          <CInputGroupText v-if="unitType">{{ unitType }}</CInputGroupText>
        </CInputGroup>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="py-2">
        <CFormSelect
          :disabled="processingUpdateBalanceAction"
          label="Adjustment Type"
          v-model="selectedAdjustmentType"
          :options="adjustmentTypes">
        </CFormSelect>
      </CCol>
    </CRow>
  </CModalBody>
  <CModalFooter>
    <TotButton @button-click="updateBalance" :disabled="processingUpdateBalanceAction">
      <template #initial>
        Update Balance
      </template>
      <template #loading>
        Updating Balance
      </template>
    </TotButton>
  </CModalFooter>
</CModal>
</template>

<script>
import TotButton from './common/tot-button.vue'
import { BALANCE_UNIT_TYPE_SERVICE_SPECIFIC, BALANCE_UNIT_TYPE_TIME, BALANCE_UNIT_TYPE_VOLUME } from '@/utils/constants'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    processingUpdateBalanceAction: {
      type: Boolean,
      default: false
    },
    balanceUnitType: {
      type: String,
      default: BALANCE_UNIT_TYPE_SERVICE_SPECIFIC
    }
  },
  data () {
    return {
      adjustmentTypes: ['CREDIT', 'DEBIT', 'SET'],
      selectedAdjustmentType: 'CREDIT',
      newBalanceValue: 0
    }
  },
  components: { TotButton },
  computed: {
    unitType () {
      switch (this.balanceUnitType) {
        case BALANCE_UNIT_TYPE_TIME:
          return 'min'
        case BALANCE_UNIT_TYPE_VOLUME:
          return 'MB'
      }
      return ''
    }
  },
  emits: ['updateBalance', 'modalClosed'],
  methods: {
    handleModalClose () {
      this.$emit('modalClosed')
    },
    resetNewBalanceValue () {
      this.newBalanceValue = 0
    },
    updateBalance () {
      let newBalanceValue = this.newBalanceValue
      if (!isNaN(newBalanceValue)) {
        switch (this.balanceUnitType) {
          case BALANCE_UNIT_TYPE_TIME:
            newBalanceValue *= 60
            break
          case BALANCE_UNIT_TYPE_VOLUME:
            newBalanceValue *= 1024 * 1024
        }
      }
      this.$emit('updateBalance', {
        newBalanceValue,
        adjustmentType: this.selectedAdjustmentType,
        callback: this.resetNewBalanceValue
      })
    }
  }
}
</script>

import axios from 'axios'
import router from '../router'
import { buildGraphQlPayload } from './graphql_helpers'

export const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      router.push({ name: 'SignIn' })
    } else {
      throw error
    }
  }
)

export const plainAxiosInstance = axios.create()

export const axiosGraphQlRequest = (graphQlUrl, graphQlPayload, graphQlPayloadVariables, graphQlHeaders) => {
  return axiosInstance.post(
    graphQlUrl,
    buildGraphQlPayload(graphQlPayload, graphQlPayloadVariables),
    graphQlHeaders
  )
}

<template>
<CButton :color="color" :variant="variant || undefined" :disabled="loading || disabled || undefined" :placeholder="placeholder || undefined" :size="size || undefined" @click="$emit('buttonClick')">
  <span v-if="loading">
    <slot name="loading"></slot>
    <CSpinner v-if="loading && showLoader" color="white" size="sm"/>
  </span>
  <slot name="initial" v-else></slot>
</CButton>
</template>

<script>
import { CButton } from '@coreui/vue'

export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    showLoader: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: ''
    }
  },
  emits: ['buttonClick'],
  components: [CButton]
}
</script>

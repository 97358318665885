export const GRAPHQL_DEVICE_NOT_FOUND_ERROR_CODE = 'DeviceNotFound'

export const RATING_GROUP_300_BUCKET = Array.from(Array(100).keys()).map(i => {
  return i + 300
})

export const RATING_GROUP_VOICE = 100
export const RATING_GROUP_TEXT = 200
export const RATING_GROUP_DATA = 300

export const SERVICE_TYPE_VOICE = 'Voice'
export const SERVICE_TYPE_TEXT = 'Text'
export const SERVICE_TYPE_DATA = 'Data'

export const BALANCE_TYPE_MONETARY = 'monetary'

export const BALANCE_UNIT_TYPE_SERVICE_SPECIFIC = 'SERVICE_SPECIFIC_UNITS'
export const BALANCE_UNIT_TYPE_TIME = 'TIME'
export const BALANCE_UNIT_TYPE_VOLUME = 'VOLUME'

export const RATING_GROUP_SERVICE_MAPPING = {
  [RATING_GROUP_VOICE]: SERVICE_TYPE_VOICE,
  [RATING_GROUP_TEXT]: SERVICE_TYPE_TEXT,
  [RATING_GROUP_DATA]: SERVICE_TYPE_DATA
}

export const EDR_UNLIMITED_PLAN_SERVICE_ID = 'Monetary'
export const UNLIMITED_BALANCE_VALUE = 'Unlimited'

export const N28_SUBSCRIPTION_BASE_URL = process.env.NODE_ENV === 'development' ? 'https://zhfc8btxn8.execute-api.us-west-2.amazonaws.com' : 'https://s8j52zy9ak.execute-api.us-west-2.amazonaws.com'
export const N28_SUBSCRIPTION_URL = `${N28_SUBSCRIPTION_BASE_URL}/subscribe`
export const N28_SUBSCRIPTIONS_CALLBACK_URL = `${N28_SUBSCRIPTION_BASE_URL}/`
export const AVAILABLE_PLAN_OVERRIDES = [
  {
    field: 'alignToBillingDoM',
    type: 'BOOLEAN',
    scope: 'Plan',
    referencedField: 'ProratingOptionsInput.alignToBillingDoM',
    values: ['true', 'false']
  },
  {
    field: 'fee',
    type: 'NUMBER',
    scope: 'Plan',
    referencedField: 'fee'
  },
  {
    field: 'first usage fee',
    type: 'NUMBER',
    scope: 'Plan',
    referencedField: 'firstUsageFee'
  },
  {
    field: 'number of periods',
    type: 'NUMBER',
    scope: 'Plan',
    referencedField: 'RecurringPeriodInput.numberOfPeriods'
  },
  {
    field: 'recurring',
    type: 'BOOLEAN',
    scope: 'Plan',
    referencedField: 'RecurringPeriodInput.recurring',
    values: ['true', 'false']
  },
  {
    field: 'recurring period',
    type: 'STRING',
    scope: 'Plan',
    referencedField: 'RecurringPeriodInput.numberOfPeriods'
  },
  {
    field: 'refundPolicy',
    type: 'STRING (NONE / FULL_REFUND / TIME_BASED)',
    scope: 'Plan',
    referencedField: 'ProratingOptionsInput.refundPolicy',
    values: ['NONE', 'FULL_REFUND', 'TIME_BASED']
  },
  {
    field: 'renewalGracePeriod',
    type: 'STRING',
    scope: 'Plan',
    referencedField: 'InitialTemplateInstance.renewalGracePeriod'
  },
  {
    field: 'subscriptionPolicy',
    type: 'STRING (NONE / TIME_BASED)',
    scope: 'Plan',
    referencedField: 'ProratingOptionsInput.subscriptionPolicy',
    values: ['NONE', 'TIME_BASED']
  },
  {
    field: 'purchase fee',
    type: 'NUMBER',
    scope: 'Plan',
    referencedField: 'InitialTemplateInstance.purchaseFee'
  },
  {
    field: 'recurring units',
    type: 'NUMBER',
    scope: 'Plan Service',
    referencedField: 'UnitAllowance.periodAllowance'
  },
  {
    field: 'rollover',
    type: 'BOOLEAN',
    scope: 'Plan Service',
    referencedField: 'UnitAllowanceInput.rollover',
    values: ['true', 'false']
  },
  {
    field: 'workingHours',
    type: 'JSON',
    scope: 'Plan Service',
    referencedField: 'InitialTemplateServiceInput.workingHours'
  }
]

export const AWS_REGIONS_INFO = {
  'us-east-1': {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_foZ45Yf8D',
    aws_user_pools_web_client_id: '58jcnn51q51crrlmvrque7q0du',
    aws_appsync_graphqlEndpoint: 'https://gql.produseast1.api.totogi.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    charging_url: 'https://5g.produseast1.api.totogi.com/nchf-convergedcharging/v3/chargingData'
  },
  'ap-southeast-1': {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'ap-southeast-1',
    aws_user_pools_id: 'ap-southeast-1_f5XO01S4q',
    aws_user_pools_web_client_id: '6dm5labfmiqsbofgbbtdvkkrov',
    aws_appsync_graphqlEndpoint: 'https://gql.prodapsoutheast1.api.totogi.com/graphql',
    aws_appsync_region: 'ap-southeast-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    charging_url: 'https://5g.prodapsoutheast1.api.totogi.com/nchf-convergedcharging/v3/chargingData'
  }
}

export const DEFAULT_AWS_REGION = 'us-east-1'

import { axiosInstance as axios } from '../../utils/axiosInstances'
import { notificationsEventBusArn } from '@/store/common'
import { getMyProviderConfig, updateProviderConfigEventBusArn, getFieldMappingsQuery, getRatingGroupHierarchyQuery, getBalanceTypesQuery } from '../modules/queries'
import { buildUnitTypeMapping } from '../index'

export const initialState = () => {
  return {}
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    resetState (state) {
      Object.assign(state, initialState())
    },
    setProviderConfigEventBusArn (state, eventBusArn) {
      state.providerConfigEventBusArn = eventBusArn
    }
  },
  actions: {
    async getMyProviderConfig (context) {
      const idToken = await context.rootState.idToken
      const providerConfigResponse = await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        getMyProviderConfig(), {
          headers: {
            Authorization: `${idToken}`
          }
        }
      )
      console.log('My provider config response:')
      console.log(providerConfigResponse)
      try {
        const providerConfigData = providerConfigResponse.data.data.getMyProviderConfig
        if (providerConfigData.__typename !== 'ProviderConfig') {
          throw new Error('Error while fetching ProviderConfig!')
        }
        const homeNetworks = providerConfigData.homeNetworks
        const eventBusArn = providerConfigData.eventBusArn
        console.log('homeNetworks:', homeNetworks)
        console.log('Event Bus ARN', eventBusArn)
        let mcc = homeNetworks.substr(0, 3)
        let mnc = homeNetworks.substr(3)
        console.log('mcc:', mcc)
        console.log('mnc:', mnc)
        context.commit('updateMcc', mcc, { root: true })
        context.commit('updateMnc', mnc, { root: true })
        context.commit('setProviderConfigEventBusArn', eventBusArn)
        console.log('Checking for telcos')
        if (mcc && mnc) {
          mcc = String(mcc)
          mnc = String(mnc)
          if (mnc.length < 2) {
            mnc = `0${mnc}`
          }
          const telcoCountries = context.rootState.settings.telcosByCountries
          for (const country of Object.keys(telcoCountries)) {
            const telcos = telcoCountries[country]
            for (const telco of telcos.telcosWithCode) {
              if (telco.mcc === String(mcc) && telco.mnc === String(mnc)) {
                console.log('Found telco for default mcc/mnc', telco.label)
                context.commit('settings/setTelcoCountry', country, { root: true })
                context.commit('settings/setTelco', telco.label, { root: true })
              }
            }
          }
        }
      } catch (e) {
        console.error('Setting MCC and MNC failed: ', e.toString())
      }
      const fieldMappings = (await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        getFieldMappingsQuery(context.rootState.providerId, 25),
        context.rootGetters.graphQLHeaders
      )).data.data.getFieldMappings
      console.log('Field mappings found', fieldMappings)

      const ratingGroupHierarchyResponse = await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        getRatingGroupHierarchyQuery(context.rootState.providerId), {
          headers: {
            Authorization: `${idToken}`
          }
        }
      )
      console.log('Rating Group Hierarchy response:')
      console.log(ratingGroupHierarchyResponse)
      try {
        const rootRatingGroup = ratingGroupHierarchyResponse.data.data.getRatingGroupHierarchy
        console.log('Root rating group', rootRatingGroup)
        if (rootRatingGroup.name === 'Root') {
          const updatedDataSubGroups = {}
          const subDataGroups = ['netflix', 'podcast', 'whatsapp', 'instagram']
          for (const baseRatingGroup of rootRatingGroup.children) {
            console.log('Base rating group', baseRatingGroup)
            switch (baseRatingGroup.name) {
              case 'Voice':
                context.commit('setRatingGroup', { ratingGroup: 'voice', value: baseRatingGroup.id }, { root: true })
                context.commit('addRatingGroupBucket', { ratingGroup: 'voice', value: baseRatingGroup.id }, { root: true })
                for (const voiceSubRatingGroup of baseRatingGroup.children) {
                  console.log('Voice sub rating group', voiceSubRatingGroup)
                  context.commit('addRatingGroupBucket', { ratingGroup: 'voice', value: voiceSubRatingGroup.id }, { root: true })
                }
                break
              case 'Text':
                context.commit('setRatingGroup', { ratingGroup: 'text', value: baseRatingGroup.id }, { root: true })
                context.commit('addRatingGroupBucket', { ratingGroup: 'text', value: baseRatingGroup.id }, { root: true })
                for (const textSubRatingGroup of baseRatingGroup.children) {
                  console.log('Text sub rating group', textSubRatingGroup)
                  context.commit('addRatingGroupBucket', { ratingGroup: 'text', value: textSubRatingGroup.id }, { root: true })
                }
                break
              case 'Data':
                context.commit('setRatingGroup', { ratingGroup: 'data', value: baseRatingGroup.id }, { root: true })
                context.commit('addRatingGroupBucket', { ratingGroup: 'data', value: baseRatingGroup.id }, { root: true })
                for (const dataSubRatingGroup of baseRatingGroup.children) {
                  console.log('Data sub rating group', dataSubRatingGroup)
                  context.commit('addRatingGroupBucket', { ratingGroup: 'data', value: dataSubRatingGroup.id }, { root: true })
                  for (const subDataGroup of subDataGroups) {
                    if (dataSubRatingGroup.name.indexOf(subDataGroup) > -1) {
                      context.commit('setRatingGroup', { ratingGroup: subDataGroup, value: dataSubRatingGroup.id }, { root: true })
                      updatedDataSubGroups[subDataGroup] = true
                    }
                  }
                }
            }
          }
          for (const subDataGroup of subDataGroups) {
            if (!updatedDataSubGroups[subDataGroup]) {
              context.commit('setRatingGroup', { ratingGroup: subDataGroup, value: context.rootState.dataRatingGroup }, { root: true })
            }
          }
          context.commit('updateUnitTypeMapping', buildUnitTypeMapping(context.rootState), { root: true })
        }
      } catch (err) {
        console.error('Error setting rating group hierarchy: ', err.toString())
      }

      const getBalanceTypesResponse = await axios.post(
        context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
        getBalanceTypesQuery(context.rootState.providerId), {
          headers: {
            Authorization: `${idToken}`
          }
        }
      )
      console.log('Get Balance Types response:')
      console.log(getBalanceTypesResponse)
      try {
        const balanceTypes = getBalanceTypesResponse.data.data.getBalanceTypes.balanceTypes
        console.log('Balance Types', balanceTypes)
        context.commit('setBalanceTypes', balanceTypes, { root: true })
      } catch (err) {
        console.error('Error setting rating group hierarchy: ', err.toString())
      }
    },
    async setNotificationEventBus (context) {
      await context.dispatch('getMyProviderConfig')
      if (!context.state.providerConfigEventBusArn) {
        console.log('Setting event bus ARN', notificationsEventBusArn)
        const idToken = await context.rootState.idToken
        await axios.post(
          context.rootState.awsRegionInfo.aws_appsync_graphqlEndpoint,
          updateProviderConfigEventBusArn(notificationsEventBusArn), {
            headers: {
              Authorization: `${idToken}`
            }
          }
        )
        context.commit('setProviderConfigEventBusArn', notificationsEventBusArn)
      }
      console.log('event bus ARN', context.state.providerConfigEventBusArn)
    }
  }
}

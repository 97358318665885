<template>
  <CContainer fluid class="border-top h-100 text-start">
    <div class="d-flex flex-column h-100">
      <div>
        <CTable class="mb-0 border-bottom-5" style="border-bottom-width: 20px !important;">
          <CTableHead class="bg-white">
            <CTableRow>
              <CTableHeaderCell style="width: 21%" scope="col" class="ps-4 border-bottom-dark border-bottom-2">Balance Name</CTableHeaderCell>
              <!--<CTableHeaderCell style="width: 12%" scope="col" class="border-bottom-dark border-bottom-2">Balance Type</CTableHeaderCell>
              <CTableHeaderCell style="width: 15%" scope="col" class="border-bottom-dark border-bottom-2">Allocation</CTableHeaderCell>-->
              <CTableHeaderCell style="width: 15%" scope="col" class="border-bottom-dark border-bottom-2">Used</CTableHeaderCell>
              <CTableHeaderCell style="width: 15%" scope="col" class="border-bottom-dark border-bottom-2">Available</CTableHeaderCell>
              <CTableHeaderCell style="width: 15%" scope="col" class="border-bottom-dark border-bottom-2">Total</CTableHeaderCell>
              <!--<CTableHeaderCell style="width: 15%" scope="col" class="pe-4 border-bottom-dark border-bottom-2">Percent Remaining</CTableHeaderCell>-->
            </CTableRow>
          </CTableHead>
        </CTable>
      </div>
      <div class="flex-grow-1" style="overflow: scroll;">
        <CTable>
          <CTableBody>
            <!--<CTableRow
              v-for="(allocation, key) in allPlanVersionAllocations"
              :key="key"
            >
              <CTableDataCell style="width: 21%" class="ps-4">{{ allocation.balanceName }}&nbsp;<TotBadge :copyValue="allocation.planName">{{ allocation.shortPlanName }}</TotBadge></CTableDataCell>
              <CTableDataCell style="width: 12%">{{ allocation.balanceType }}</CTableDataCell>
              <CTableDataCell style="width: 15%">{{ allocation.balanceValue }}</CTableDataCell>
              <CTableDataCell style="width: 15%">
                {{ allocation.usedUnits }}
              </CTableDataCell>
              <CTableDataCell style="width: 15%">{{ allocation.remainingBalance }}</CTableDataCell>
              <CTableDataCell style="width: 15%" class="pe-4">
                <div class="d-flex flex-row d-gap gap-2" v-if="allocation.balancePercent !== ''">
                  <div style="min-width: 41px;">{{ allocation.balancePercent }}%</div>
                  <div class="flex-grow-1">
                    <CProgress color="success" style="height: 20px;border-radius: 0px;max-width: 100px;" :value="allocation.balancePercent"></CProgress>
                  </div>
                </div>
              </CTableDataCell>
            </CTableRow>-->
            <!--<CTableRow key="monetaryBalance">
              <CTableDataCell style="width: 21%" class="ps-4">Monetary</CTableDataCell>
              <CTableDataCell style="width: 12%">{{ currencyCode }}</CTableDataCell>
              <CTableDataCell style="width: 15%"></CTableDataCell>
              <CTableDataCell style="width: 15%"></CTableDataCell>
              <CTableDataCell style="width: 15%">{{ currencySymbol }} {{ monetaryBalance }}</CTableDataCell>
              <CTableDataCell style="width: 15%" class="pe-4"></CTableDataCell>

            </CTableRow>-->
            <CTableRow
              v-for="(balance, key) in newBalances"
              :key="'newBalance' + key"
            >
              <CTableDataCell style="width: 21%" class="ps-4">{{ balance.balanceType.name }}&nbsp;<TotBadge :copyValue="balance.balanceId">{{ balance.balanceId.substring(0, 8) }}</TotBadge></CTableDataCell>
              <CTableDataCell style="width: 15%">
                {{ balance.balanceType.balanceTypeId === 'monetary' ? `${currencySymbol} ` : '' }}{{ balance.readable_used }}
              </CTableDataCell>
              <CTableDataCell style="width: 15%">
                {{ balance.balanceType.balanceTypeId === 'monetary' ? `${currencySymbol} ` : '' }}{{ balance.readable_available }}
              </CTableDataCell>
              <CTableDataCell style="width: 15%">
                {{ balance.balanceType.balanceTypeId === 'monetary' ? `${currencySymbol} ` : '' }}{{ balance.readable_total }}
              </CTableDataCell>
              <!--<CTableDataCell style="width: 15%" class="pe-4">
                <div class="d-flex flex-row d-gap gap-2" v-if="allocation.balancePercent !== ''">
                  <div style="min-width: 41px;">{{ allocation.balancePercent }}%</div>
                  <div class="flex-grow-1">
                    <CProgress color="success" style="height: 20px;border-radius: 0px;max-width: 100px;" :value="allocation.balancePercent"></CProgress>
                  </div>
                </div>
              </CTableDataCell>-->
            </CTableRow>
          </CTableBody>
        </CTable>
      </div>
    </div>
  </CContainer>
</template>

<script>
import { getReadableEntityValue } from '@/store/modules/utils/unifiedBalances'
import {
  EDR_UNLIMITED_PLAN_SERVICE_ID,
  RATING_GROUP_300_BUCKET,
  RATING_GROUP_VOICE,
  RATING_GROUP_TEXT,
  RATING_GROUP_DATA,
  RATING_GROUP_SERVICE_MAPPING,
  UNLIMITED_BALANCE_VALUE
} from '@/utils/constants'

import TotBadge from '@/components/common/tot-badge.vue'

export default {
  name: 'BalancesTable',
  data () {
    return {
      balanceRemaining: 0
    }
  },
  components: { TotBadge },
  props: {
    accountId: {
      type: String,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    }
  },
  computed: {
    newBalances () {
      return this.$store.state.device.demoAccount.balances.map(balance => {
        /* const isUnlimitedBalance = this.$store.state.device.demoAccount.activePlanVersions.filter(activePlanSubscription => {
          return activePlanSubscription.planVersion.template.services.filter(templateService => (templateService.managedBalance?.managedBalanceTypeId === balance.balanceType.balanceTypeId && templateService.managedBalance?.periodAllowance === null)).length > 0
        }).length > 0 */
        // const isUnlimitedBalance = (managedBalancePlanSubscription ? (managedBalancePlanSubscription.planVersion.template.services.filter(templateService => templateService.managedBalance?.managedBalanceTypeId === balance.balanceType.balanceTypeId)[0].periodAllowance === null) : false)
        const isUnlimitedBalance = null
        const unlimitedText = isUnlimitedBalance ? 'Unlimited' : null
        return {
          ...balance,
          readable_available: (unlimitedText || getReadableEntityValue(balance.balanceType.balanceTypeId, balance.available, balance.balanceType.unitType)),
          readable_total: (unlimitedText || getReadableEntityValue(balance.balanceType.balanceTypeId, balance.total, balance.balanceType.unitType)),
          readable_used: getReadableEntityValue(balance.balanceType.balanceTypeId, balance.used, balance.balanceType.unitType)
        }
      })
    },
    allPlanVersionAllocations () {
      return this.$store.state.device.demoAccountAllocations.map(allocation => {
        const balanceValue = allocation.readableValue
        const remainingBalance = this.getBalanceRemaining(allocation.id, allocation.value, this.$store.state.device.demoAccountBalance.serviceBalances)
        const planName = this.$store.state.device.demoAccountBalance.serviceBalances.filter(serviceBalance => serviceBalance.id === allocation.id)[0]?.planName
        const shortPlanName = ((planName?.length || 0) > 23 ? `${planName.substring(0, 23)}..` : planName)
        const planId = this.$store.state.device.demoAccountBalance.serviceBalances.filter(serviceBalance => serviceBalance.id === allocation.id)[0]?.planId
        const planSubscriptionId = this.$store.state.device.demoAccountBalance.serviceBalances.filter(serviceBalance => serviceBalance.id === allocation.id)[0]?.planSubscriptionId
        const planVersionId = allocation.planVersionId
        let balancePercent = balanceValue === 'Unlimited' ? 100 : Math.round(parseInt(remainingBalance) / parseInt(balanceValue) * 100)
        if (Number.isNaN(balancePercent)) {
          balancePercent = ''
        }
        const edges = this.$store.state.device.demoDeviceEdrEdges
        const filteredEdges = edges
          .filter(edge => allocation.id === edge.planServiceId && allocation.subscriptionStartedAt.isBefore(edge.createdAt))
        console.log(allocation.id, allocation.subscriptionStartedAt, filteredEdges, filteredEdges.reduce((accumulator, edge) => (accumulator + (edge.usedUnits ? edge.usedUnits : 0)), 0), edges
          .filter(edge => !edge.planServiceId && RATING_GROUP_300_BUCKET.indexOf(edge.ratingGroup) > -1 && allocation.subscriptionStartedAt.isBefore(edge.createdAt))
          .reduce((accumulator, edge) => (accumulator + (edge.usedUnits ? edge.usedUnits : 0)), 0))

        let usedUnits = filteredEdges.reduce((accumulator, edge) => (accumulator + (edge.usedUnits ? edge.usedUnits : 0)), 0)
        if (allocation.type === RATING_GROUP_SERVICE_MAPPING[RATING_GROUP_DATA]) {
          usedUnits += edges
            .filter(edge => !edge.planServiceId && RATING_GROUP_300_BUCKET.indexOf(edge.ratingGroup) > -1 && allocation.subscriptionStartedAt.isBefore(edge.createdAt))
            .reduce((accumulator, edge) => (accumulator + (edge.usedUnits ? edge.usedUnits : 0)), 0)
        }
        if (balanceValue === UNLIMITED_BALANCE_VALUE && allocation.type === RATING_GROUP_SERVICE_MAPPING[RATING_GROUP_DATA]) {
          usedUnits += edges
            .filter(edge => edge.planServiceId === EDR_UNLIMITED_PLAN_SERVICE_ID && edge.ratingGroup !== RATING_GROUP_DATA && RATING_GROUP_300_BUCKET.indexOf(edge.ratingGroup) > -1 && allocation.subscriptionStartedAt.isBefore(edge.createdAt))
            .reduce((accumulator, edge) => (accumulator + (edge.usedUnits ? edge.usedUnits : 0)), 0)
        }
        for (const ratingGroup of [RATING_GROUP_VOICE, RATING_GROUP_TEXT, RATING_GROUP_DATA]) {
          if (balanceValue === UNLIMITED_BALANCE_VALUE && allocation.type === RATING_GROUP_SERVICE_MAPPING[ratingGroup]) {
            usedUnits += edges
              .filter(edge => edge.planServiceId === EDR_UNLIMITED_PLAN_SERVICE_ID && edge.ratingGroup === ratingGroup && allocation.subscriptionStartedAt.isBefore(edge.createdAt))
              .reduce((accumulator, edge) => (accumulator + (edge.usedUnits ? edge.usedUnits : 0)), 0)
          }
        }
        usedUnits = getReadableEntityValue(allocation.type, usedUnits)
        return {
          id: allocation.id,
          balanceName: allocation.name,
          balanceId: allocation.uuid.split('-')[0],
          balanceType: allocation.type,
          balanceValue,
          remainingBalance,
          balancePercent,
          usedUnits,
          planVersionId,
          planId,
          planName,
          planSubscriptionId,
          shortPlanName
        }
      })
    },
    monetaryBalance () {
      return Math.round(this.$store.state.device.demoAccountBalance.monetaryBalance * 100) / 100
    },
    currencySymbol () {
      return this.$store.state.settings.currencySymbol
    },
    currencyCode () {
      return this.$store.state.settings.currencyCode
    }
  },
  mounted () {
    console.log('Ready')
    this.loadData()
  },
  beforeUnmount () {
    this.$store.dispatch('device/clearDemoDeviceRefresh')
    this.$store.dispatch('device/clearN28NotificationSubscription')
  },
  methods: {
    loadData: function () {
      this.$store.dispatch('device/refreshDemoDeviceBalanceAndInfo')
      this.$store.dispatch('device/initiateDemoDeviceRefresh')
    },
    getBalanceRemaining (allocationId, allocationValue, balances) {
      if (!allocationValue || allocationValue === 'Unlimited') {
        return 'Unlimited'
      }
      for (const balance of balances) {
        if (balance.id === allocationId) {
          const balanceValue = balance.value
          return this.allocationOrBalanceValue(balanceValue, balance.type)
        }
      }
    },
    allocationOrBalanceValue (allocationValue, balanceType) {
      if (allocationValue === null) {
        return 'Unlimited'
      }
      if (balanceType === 'Voice') {
        return (allocationValue / 60).toString() + ' mins'
      } else if (balanceType === 'Data') {
        return (allocationValue / (1024 * 1024)).toString() + ' MB'
      } else {
        return (allocationValue).toString() + ' messages'
      }
    }
  }
}
</script>

<style>
.table-scroll{
  /*width:100%; */
  display: block;
  empty-cells: show;
}

.table-scroll thead{
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.table-scroll tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
}

.table-scroll tr{
  width: 100%;
  display:flex;
}

.table-scroll td,.table-scroll th{
  flex-basis:100%;
  flex-grow:2;
  display: block;
}

/* Other options */

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}

.table-body-limited-height{
  max-height: 300px;
}

.small-col{flex-basis:10%;}
</style>

<template>
<CModal
  :backdrop="true"
  :centered="true"
  alignment="center"
  :visible="showModal"
  @close="handleModalClose"
>
  <CModalHeader>
    <CModalTitle>
      <slot name="title">Confirm Action</slot>
    </CModalTitle>
  </CModalHeader>
  <CModalBody class="text-start">
    <slot name="confirmationText"></slot>
  </CModalBody>
  <CModalFooter>
    <TotButton color="secondary" variant="outline" :showLoader="false" :loading="processingAction" @button-click="handleModalClose">
      <template #loading>
        <slot name=modalProcessingText>Cancel</slot>
      </template>
      <template #initial>
        <slot name="modalSubmitText">Cancel</slot>
      </template>
    </TotButton>
    <TotButton :loading="processingAction" @button-click="submitModal">
      <template #loading>
        <slot name=modalProcessingText>Processing</slot>
        &nbsp;
      </template>
      <template #initial>
        <slot name="modalSubmitText">Confirm</slot>
      </template>
    </TotButton>
  </CModalFooter>
</CModal>
</template>

<script>
import TotButton from './tot-button.vue'
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    processingAction: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputValue: ''
    }
  },
  emits: ['modalSubmitted', 'modalClosed'],
  methods: {
    handleModalClose () {
      this.$emit('modalClosed')
    },
    submitModal () {
      this.$emit('modalSubmitted')
    }
  },
  components: { TotButton }
}
</script>

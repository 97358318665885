import moment from 'moment-timezone'
import { createStore } from 'vuex'
import { AWS_REGIONS_INFO, DEFAULT_AWS_REGION } from '../utils/constants.js'

import account from './modules/account.js'
import accountBalances from './modules/accountBalances.js'
import charge5g from './modules/charge5g.js'
import device from './modules/device.js'
import edrs from './modules/edrs.js'
import notifications from './modules/notifications.js'
import planVersionAllocations from './modules/planVersionAllocations.js'
import myProviderConfig from './modules/myProviderConfig.js'
import planVersions from './modules/planVersions.js'
import signIn from './modules/signIn.js'
import unitMapping from './modules/unitMapping.js'
import credit5g from './modules/credit5g.js'
import logging from './modules/logging.js'
import settings from './modules/settings.js'
import user from './modules/user.js'

const DEFAULT_UNIT_TYPE = 'totalVolume'

export const buildUnitTypeMapping = (state) => {
  const unitTypeMapping = {
    400: 'serviceSpecificUnits'
  }
  unitTypeMapping[(state?.voiceRatingGroup || 100)] = 'time'
  unitTypeMapping[(state?.textRatingGroup || 200)] = 'serviceSpecificUnits'
  unitTypeMapping[(state?.dataRatingGroup || 300)] = 'totalVolume'
  Array.from(Array(100).keys()).forEach(i => {
    const key = i + 300
    const value = unitTypeMapping[key] || DEFAULT_UNIT_TYPE
    unitTypeMapping[key] = value
  })
  unitTypeMapping[state?.netflixRatingGroup] = 'totalVolume'
  unitTypeMapping[state?.instagramRatingGroup] = 'totalVolume'
  unitTypeMapping[state?.podcastRatingGroup] = 'totalVolume'
  unitTypeMapping[state?.whatsappRatingGroup] = 'totalVolume'
  return unitTypeMapping
}

export const initialState = () => {
  return {
    verboseLogging: false,
    idToken: '',
    providerId: '',
    unitTypeMapping: buildUnitTypeMapping(),
    deviceLogs: {},
    zeroRatedData: false,
    accountDeviceError: false,
    currentSmsBalance: 'loading...',
    currentVoiceBalanceMinutes: 'loading...',
    currentDataBalanceMb: 'loading...',
    currentMonetaryBalance: 'loading...',
    currentNonMonetaryBalances: [],
    planVersionSmsAllocation: null,
    planVersionVoiceAllocationMinutes: null,
    planVersionDataAllocationMb: null,
    allAvailablePlanInformation: {},
    allPlanVersionAllocations: [],
    currentPlanInformation: {},
    currentUser: {},
    accounts: {},
    mcc: '244',
    mnc: '09',
    dispatchedProviderConfig: false,
    awsRegionInfo: AWS_REGIONS_INFO[DEFAULT_AWS_REGION],
    voiceRatingGroup: 100,
    textRatingGroup: 200,
    dataRatingGroup: 300,
    voiceRatingGroupBucket: [100],
    textRatingGroupBucket: [200],
    dataRatingGroupBucket: [300],
    netflixRatingGroup: 300,
    podcastRatingGroup: 300,
    whatsappRatingGroup: 300,
    instagramRatingGroup: 300,
    balanceTypes: [],
    appTimezone: moment.tz.guess()
  }
}

export default createStore({
  state: initialState,
  getters: {
    graphQLHeaders (state) {
      return {
        headers: {
          Authorization: `${state.idToken}`,
          region: state.awsRegionInfo.aws_appsync_region
        }
      }
    },
    persistenceGraphQLHeaders (state) {
      return {
        headers: {
          Authorization: `${state.idToken}|${state.awsRegionInfo.aws_appsync_region}`,
          region: state.awsRegionInfo.aws_appsync_region
        }
      }
    }
  },
  mutations: {
    setBalanceTypes (state, balanceTypes) {
      state.balanceTypes = balanceTypes
    },
    addRatingGroupBucket (state, { ratingGroup, value }) {
      const ratingGroupBucket = state[`${ratingGroup}RatingGroupBucket`]
      if (ratingGroupBucket.indexOf(value) < 0) {
        ratingGroupBucket.push(value)
      }
    },
    setRatingGroup (state, { ratingGroup, value }) {
      state[`${ratingGroup}RatingGroup`] = value
    },
    setRatingGroupBucket (state, { ratingGroup, value }) {
      state[`${ratingGroup}RatingGroupBucket`] = value
    },
    resetState (state) {
      Object.assign(state, initialState())
    },
    toggleVerboseLogging (state) {
      state.verboseLogging = !state.verboseLogging
    },
    setAWSRegionInfo (state, awsRegionName) {
      console.log('Setting AWS Region', awsRegionName, AWS_REGIONS_INFO[awsRegionName], AWS_REGIONS_INFO)
      state.awsRegionInfo = AWS_REGIONS_INFO[awsRegionName] || AWS_REGIONS_INFO[DEFAULT_AWS_REGION]
    },
    setProviderId (state, providerId) {
      state.providerId = providerId
    },
    setIdToken (state, idToken) {
      state.idToken = idToken
    },
    setDispatchedProviderConfig (state, dispatched) {
      state.dispatchedProviderConfig = dispatched
    },
    updateUnitTypeMapping (state, newMapping) {
      state.unitTypeMapping = newMapping
    },
    updateMcc (state, mcc) {
      state.mcc = mcc
    },
    updateMnc (state, mnc) {
      state.mnc = mnc
    },
    putAccount (state, newAccountDetails) {
      state.accounts[newAccountDetails.accountId] = newAccountDetails
    },
    addLog (state, { log, deviceId }) {
      // Check if the device ID has a log setup for it
      // otherwise create it
      if (!(deviceId in state.deviceLogs)) {
        state.deviceLogs[deviceId] = []
      }
      let logLengthLimit
      if (state.verboseLogging) {
        state.deviceLogs[deviceId].push(log)
        logLengthLimit = 4
      } else {
        state.deviceLogs[deviceId].push(log)
        logLengthLimit = 15
      }
      const ids = state.deviceLogs[deviceId].map(o => o.id)
      const filtered = state.deviceLogs[deviceId].filter(({ id }, index) => !ids.includes(id, index + 1))
      state.deviceLogs[deviceId] = filtered
      state.deviceLogs[deviceId].sort((a, b) => {
        if (a.id < b.id) {
          return -1
        }
        if (a.id > b.id) {
          return 1
        }
        return 0
      })
      if (state.deviceLogs[deviceId].length > logLengthLimit) {
        state.deviceLogs[deviceId].shift()
      }
    },
    setAccountDeviceError (state, errorState) {
      state.accountDeviceError = errorState
    },
    setPlanVersionSmsAllocation (state, planVersionSmsAllocation) {
      state.planVersionSmsAllocation = planVersionSmsAllocation
    },
    setPlanVersionVoiceAllocationMinutes (state, planVersionVoiceAllocationMinutes) {
      state.planVersionVoiceAllocationMinutes = planVersionVoiceAllocationMinutes
    },
    setPlanVersionDataAllocationMb (state, planVersionDataAllocationMb) {
      state.planVersionDataAllocationMb = planVersionDataAllocationMb
    },
    setCurrentSmsBalance (state, currentSmsBalance) {
      state.currentSmsBalance = currentSmsBalance
    },
    setCurrentVoiceBalanceMinutes (state, currentVoiceBalanceMinutes) {
      state.currentVoiceBalanceMinutes = currentVoiceBalanceMinutes
    },
    setCurrentDataBalanceMb (state, currentDataBalanceMb) {
      state.currentDataBalanceMb = currentDataBalanceMb
    },
    setCurrentMonetaryBalance (state, currentMonetaryBalance) {
      state.currentMonetaryBalance = currentMonetaryBalance
    },
    setCurrentNonMonetaryBalances (state, currentNonMonetaryBalances) {
      state.currentNonMonetaryBalances = currentNonMonetaryBalances
    },
    setCurrentPlanInformation (state, currentPlanInformation) {
      state.currentPlanInformation = currentPlanInformation
      state.currentPlanVersionId = currentPlanInformation.planVersionId
    },
    setAllAvailablePlanInformation (state, allAvailablePlanInformation) {
      state.allAvailablePlanInformation = allAvailablePlanInformation
    },
    setAllPlanVersionAllocations (state, allowances) {
      state.allPlanVersionAllocations = allowances
    },
    setCurrentUser (state, user) {
      state.currentUser = user
    }
  },
  actions: {
    resetAll (context) {
      context.commit('resetState')
      const rootState = context.rootState
      const initialStateValues = initialState()
      Object.keys(initialState()).forEach(key => {
        rootState[key] = initialStateValues[key]
      })
      const modules = [
        'account',
        'accountBalances',
        'charge5g',
        'device',
        'edrs',
        'notifications',
        'planVersionAllocations',
        'myProviderConfig',
        'planVersions',
        'signIn',
        'unitMapping',
        'credit5g',
        'logging',
        'settings',
        'user'
      ]
      for (const moduleName of modules) {
        context.commit(`${moduleName}/resetState`)
      }
    }
  },
  modules: {
    account: account,
    accountBalances: accountBalances,
    charge5g: charge5g,
    credit5g: credit5g,
    device: device,
    edrs: edrs,
    logging: logging,
    notifications: notifications,
    planVersionAllocations: planVersionAllocations,
    myProviderConfig: myProviderConfig,
    planVersions: planVersions,
    signIn: signIn,
    unitMapping: unitMapping,
    user: user,
    settings: settings
  }
})

<template>
  <div>
    <MainNav />
    <br />
    <AccountDetails />
  </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue'
import AccountDetails from '@/components/AccountDetails.vue'

export default {
  name: 'HomeView',
  components: {
    MainNav,
    AccountDetails
  }
}
</script>
